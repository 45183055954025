import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import Flex from '../components/Flex';
import Page from '../components/Page';
import Textarea from '../components/Textarea';
import TextField from '../components/TextField';
import { PRODUCT_QUERY, PROVIDER_QUERY } from '../graphql/graphqlQueries';
import { useCreateServiceMutation, useServiceQuery, useUpdateServiceMutation } from '../graphql/types';

const CreateAndEditServiceSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  priceFrom: Yup.number()
    .integer('Pouze celé číslo.')
    // .positive('Pouze kladné celé číslo.')
    // .min(1, 'Minimálně za 1 Kč.')
    .nullable(),
  priceTo: Yup.number()
    .integer('Pouze celé číslo.')
    .positive('Pouze kladné celé číslo.')
    .min(1, 'Minimálně za 1 Kč.')
    .nullable(),
  description: Yup.string().min(2, 'Zadejte minimálně 2 znaky').required('Toto pole je povinné.'),
  priority: Yup.number().integer('Pouze celé číslo.').positive('Pouze kladné celé číslo.').nullable(),
});

const CreateServicePage = () => {
  const navigate = useNavigate();
  const { serviceId } = useParams<'serviceId'>();
  const { providerId } = useParams<'providerId'>();

  const serviceIdParam = serviceId !== 'add' ? serviceId : undefined;
  const serviceQuery = useServiceQuery({ variables: { id: serviceIdParam + '' }, skip: !serviceIdParam });
  const [executeCreateServiceMutation, createServiceMutation] = useCreateServiceMutation();
  const [executeUpdateServiceMutation, updateServiceMutation] = useUpdateServiceMutation();

  if (serviceIdParam && !serviceQuery.data) {
    return <Page title="Upravit službu" isLoading={serviceQuery.loading} isError={!!serviceQuery.error} />;
  }

  if (
    (createServiceMutation && createServiceMutation.data && getValue(createServiceMutation.data, 'createService.id')) ||
    (updateServiceMutation && updateServiceMutation.data && getValue(updateServiceMutation.data, 'updateService.id'))
  ) {
    navigate(`/providers/${providerId}`);
  }

  return (
    <Page title={!serviceIdParam ? 'Přidat službu' : 'Upravit službu'}>
      <Formik
        initialValues={{
          name: serviceIdParam ? getValue(serviceQuery, 'data.service.name') : '',
          description: serviceIdParam ? getValue(serviceQuery, 'data.service.description') : '',
          priceFrom: serviceIdParam ? getValue(serviceQuery, 'data.service.priceFrom') : '',
          priceTo: serviceIdParam ? getValue(serviceQuery, 'data.service.priceTo') : '',
          priority: serviceIdParam ? getValue(serviceQuery, 'data.service.priority') : '',
        }}
        isInitialValid={!!serviceIdParam}
        validationSchema={CreateAndEditServiceSchema}
        validate={(values) => {
          let errors: { priceTo?: string } = {};

          if (values.priceFrom && values.priceTo && values.priceFrom >= values.priceTo) {
            errors.priceTo = 'Částka musí být větší než částka od.';
          }

          return errors;
        }}
        onSubmit={(values) => {
          if (serviceIdParam) {
            executeUpdateServiceMutation({
              variables: {
                ...values,
                priceFrom: values.priceFrom === '' ? null : values.priceFrom,
                priceTo: values.priceTo === '' ? null : values.priceTo,
                priority: values.priority === '' ? null : values.priority,
                id: serviceIdParam,
              },
              refetchQueries: [
                { query: PROVIDER_QUERY, variables: { id: providerId } },
                { query: PRODUCT_QUERY, variables: { id: serviceIdParam } },
              ],
            });
          } else {
            executeCreateServiceMutation({
              variables: {
                ...values,
                priceFrom: values.priceFrom === '' ? null : values.priceFrom,
                priceTo: values.priceTo === '' ? null : values.priceTo,
                priority: values.priority === '' ? null : values.priority,
                providerId: providerId ?? '',
              },
              refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {createServiceMutation.error ||
              (updateServiceMutation.error && (
                <SectionMessage appearance="error">Nepovedlo se službu uložit.</SectionMessage>
              ))}
            <Flex>
              <TextField formikProps={props} label="Název" name="name" autoFocus={!serviceIdParam} isRequired />
              <TextField formikProps={props} label="Priorita" name="priority" type="number" />
              <TextField
                formikProps={props}
                label="Cena od"
                name="priceFrom"
                type="number"
                elemAfterInput={<div style={{ marginRight: 10 }}>Kč</div>}
              />
              <TextField
                formikProps={props}
                label="Cena do"
                name="priceTo"
                type="number"
                elemAfterInput={<div style={{ marginRight: 10 }}>Kč</div>}
              />
            </Flex>

            <Textarea formikProps={props} label="Popis" name="description" minimumRows={5} isRequired />

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createServiceMutation.loading || updateServiceMutation.loading}
                isDisabled={!props.isValid || createServiceMutation.loading || updateServiceMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateServicePage;
