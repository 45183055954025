import React from 'react';

interface Props {
  name: string;
  color?: string;
  size?: number;
  style?: any;
}

const Icon = ({ name, color = '#515f79', size = 20, style }: Props) => (
  <img
    src={`https://img.icons8.com/ios/${size * 2}/${color.replace('#', '')}/${name}.png`}
    style={{ width: size, height: size, ...style }}
    alt={`Ikona ${name}`}
  />
);

export default Icon;
