import React from 'react';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import Flex from './Flex';
import Menu from './Menu';

const Page = (props: { title?: string; children?: any; isLoading?: boolean; isError?: boolean; actions?: any }) => (
  <div>
    <Menu />
    <Wrapper>
      <Flex>
        {props.title && <Title>{props.title}</Title>}
        <ActionsWrapper>{props.actions}</ActionsWrapper>
      </Flex>

      {props.isLoading && (
        <SpinnerWrapper>
          <Spinner size="large" />
        </SpinnerWrapper>
      )}
      {props.isError && 'Error'}
      {!props.isLoading && !props.isError && props.children}
    </Wrapper>
  </div>
);

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  font-size: 30px;
`;

export default Page;
