import '@atlaskit/css-reset';
import 'moment/locale/cs';
import cookies from 'js-cookie';
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { CloudimageProvider } from 'react-cloudimage-responsive';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import apolloClient from './apollo-client';
import AddUserPage from './pages/addUser';
import AddUserToProvider from './pages/addUserToProvider';
import BuildsPage from './pages/builds';
import CreatePhotoPage from './pages/createPhoto';
import CreatePostPage from './pages/createPost';
import CreateProductPage from './pages/createProduct';
import CreateProviderPage from './pages/createProvider';
import CreateRatingPage from './pages/createRating';
import CreateServicePage from './pages/createService';
import CreateStorePage from './pages/createStore';
import CreateVideoPage from './pages/createVideo';
import LoginPage from './pages/login';
import PostsPage from './pages/posts';
import ProviderPage from './pages/provider';
import ProvidersPage from './pages/providers';
import SetUserTokenPage from './pages/setUserToken';
import UsersPage from './pages/usersPage';
import UsersShowPage from './pages/usersShowPage';
import PricingsPage from './pages/pricings';
import PhotosPage from './pages/photos';

const UserRoute = ({ children }) => {
  const isAuth = cookies.get('token');
  return isAuth ? children : <Navigate to="/login" replace state={{ from: window.location.pathname }} />;
};

const App = () => {
  const cloudimageConfig = {
    token: 'athhnphopm',
    lazyLoading: false,
    doNotReplaceURL: false,
  };
  return (
    <ApolloProvider client={apolloClient}>
      <CloudimageProvider config={cloudimageConfig}>
        <Router>
          <div>
            <Routes>
              <Route path="/" Component={() => <Navigate to="/providers" />} />
              <Route path="/login" Component={LoginPage} />
              <Route path="/login/:token" Component={SetUserTokenPage} />

              <Route
                path="/providers"
                element={
                  <UserRoute>
                    <ProvidersPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/add"
                element={
                  <UserRoute>
                    <CreateProviderPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId"
                element={
                  <UserRoute>
                    <ProviderPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/edit"
                element={
                  <UserRoute>
                    <CreateProviderPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/photo/:photoId"
                element={
                  <UserRoute>
                    <CreatePhotoPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/photo/add"
                element={
                  <UserRoute>
                    <CreatePhotoPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/product/:productId"
                element={
                  <UserRoute>
                    <CreateProductPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/product/add"
                element={
                  <UserRoute>
                    <CreateProductPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/service/:serviceId"
                element={
                  <UserRoute>
                    <CreateServicePage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/service/add"
                element={
                  <UserRoute>
                    <CreateServicePage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/video/:videoId"
                element={
                  <UserRoute>
                    <CreateVideoPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/video/add"
                element={
                  <UserRoute>
                    <CreateVideoPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/store/:storeId"
                element={
                  <UserRoute>
                    <CreateStorePage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/store/add"
                element={
                  <UserRoute>
                    <CreateStorePage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/user/add"
                element={
                  <UserRoute>
                    <AddUserToProvider />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/rating/add"
                element={
                  <UserRoute>
                    <CreateRatingPage />
                  </UserRoute>
                }
              />
              <Route
                path="/providers/:providerId/rating/:ratingId"
                element={
                  <UserRoute>
                    <CreateRatingPage />
                  </UserRoute>
                }
              />
              <Route
                path="/posts"
                element={
                  <UserRoute>
                    <PostsPage />
                  </UserRoute>
                }
              />
              <Route
                path="/posts/:postId"
                element={
                  <UserRoute>
                    <CreatePostPage />
                  </UserRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <UserRoute>
                    <UsersPage />
                  </UserRoute>
                }
              />
              <Route
                path="/usersShow"
                element={
                  <UserRoute>
                    <UsersShowPage />
                  </UserRoute>
                }
              />
              <Route
                path="/users/:userId"
                element={
                  <UserRoute>
                    <AddUserPage />
                  </UserRoute>
                }
              />
              <Route
                path="/users/add"
                element={
                  <UserRoute>
                    <AddUserPage />
                  </UserRoute>
                }
              />
              <Route
                path="/builds"
                element={
                  <UserRoute>
                    <BuildsPage />
                  </UserRoute>
                }
              />
              <Route
                path="/pricing"
                element={
                  <UserRoute>
                    <PricingsPage />
                  </UserRoute>
                }
              />
              <Route
                path="/photos"
                element={
                  <UserRoute>
                    <PhotosPage />
                  </UserRoute>
                }
              />

              <Route Component={() => <p>#404</p>} />
            </Routes>
          </div>
        </Router>
      </CloudimageProvider>
    </ApolloProvider>
  );
};

export default App;
