import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import Page from '../components/Page';
import { PROVIDER_QUERY } from '../graphql/graphqlQueries';
import { useAddUserToProviderMutation, useProviderQuery, useUsersQuery } from '../graphql/types';

const CreateProductPage = () => {
  const navigate = useNavigate();
  const { providerId } = useParams<'providerId'>();
  const providerQuery = useProviderQuery({ variables: { id: providerId ?? '' } });
  const usersFetcher = useUsersQuery();
  const [executeAddUserToProvider, addUserToProviderMutation] = useAddUserToProviderMutation();

  if (providerQuery.loading || !providerQuery.data || usersFetcher.loading || !usersFetcher.data) {
    return null;
  }

  if (
    addUserToProviderMutation &&
    addUserToProviderMutation.data &&
    getValue(addUserToProviderMutation.data, 'addUserToProvider.id')
  ) {
    navigate(`/providers/${providerId}`);
  }

  return (
    <Page title={`Přidat správce dodavatele (${providerQuery.data.provider.name})`}>
      <Formik
        initialValues={{
          user: '',
        }}
        onSubmit={(values) => {
          executeAddUserToProvider({
            variables: {
              provider: providerId ?? '',
              user: values.user,
            },
            refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
          });
        }}
      >
        {(props) => (
          <Form>
            {addUserToProviderMutation.error && (
              <SectionMessage appearance="error">Nepovedlo se uživatele přidat k dodavateli.</SectionMessage>
            )}
            <Card title="Uživatel">
              <Select
                options={
                  usersFetcher.data && usersFetcher.data.users
                    ? [
                        ...usersFetcher.data.users.map((user) => ({
                          label: `${user.firstName} ${user.lastName} (${user.email})`,
                          value: user.id,
                        })),
                      ]
                    : {}
                }
                name="users"
                onChange={(item: { value: string; label: string }) => {
                  props.setFieldValue('user', item.value);
                }}
                value={
                  usersFetcher.data &&
                  usersFetcher.data.users
                    .map((user) => ({ label: user.firstName + ' ' + user.lastName, value: user.id }))
                    .find((user) => user.value === props.values.user)
                }
                isSearchable={true}
                placeholder="Vyberte existujícího uživatele"
              />
            </Card>

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={addUserToProviderMutation.loading}
                isDisabled={!props.isValid || addUserToProviderMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateProductPage;
