import styled from 'styled-components';

const Flex = styled.div<{ columns?: number }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  & > div {
    flex: 0 0 ${props => (props.columns ? 100 / props.columns - 2 : 48)}%;

    @media screen and (max-width: 600px) {
      flex: 0 0 100%;
    }

    [class^='Content__'],
    [class^='Content-'] {
      width: 100% !important;
    }
  }
`;

export default Flex;
