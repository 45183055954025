import React from 'react';

const LoginWrapper = (props: { children?: any }) => (
  <div
    style={{
      minHeight: '100vh',
      background: '#f7bfb5',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <img src="/logo.png" style={{ width: 220, marginBottom: 50, zIndex: 2, position: 'relative' }} alt="Svatba Logo" />
    <div
      style={{
        width: '400px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding: '40px 30px',
        borderRadius: 10,
        zIndex: 2,
        position: 'relative',
      }}
    >
      {props.children}
    </div>
  </div>
);

export default LoginWrapper;
