import { gql } from '@apollo/client/core';
import get from 'get-value';
import * as React from 'react';
import Img from 'react-cloudimage-responsive';
import Files from 'react-files';
import Button, { ButtonGroup } from '@atlaskit/button';
import { Field } from '@atlaskit/form';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import client from '../apollo-client';
import Icon from './Icon';

class FileField extends React.Component<
  {
    defaultState?: string;
    updateParentState: (url: string) => void;
    width?: number;
    height?: number;
    isInvalid?: boolean;
    photoName: string;
  },
  { hover: boolean; uploading: boolean; url: undefined | string; error: boolean | any }
> {
  state = {
    hover: false,
    uploading: false,
    url: undefined,
    error: false,
  };

  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }

  componentDidMount() {
    const { defaultState } = this.props;

    if (defaultState) {
      this.setGlobalUrlState(defaultState);
    }
  }

  setGlobalUrlState(url: string) {
    this.props.updateParentState(url);

    this.setState({ url });
  }

  async fileUpload(files: string[], photoName: string) {
    this.setState({ uploading: true });

    if (files.length === 0) {
      this.setState({
        error: <SectionMessage appearance="error">Nepovedlo se obrázek nahrát. Zkuste to prosím znovu.</SectionMessage>,
        uploading: false,
        hover: false,
      });
      return;
    }

    const uploadedFile = await client.mutate({
      mutation: gql`
        mutation uploadFile($file: Upload!, $name: String) {
          uploadFile(file: $file, name: $name)
        }
      `,
      variables: { file: files[0], name: photoName },
    });

    const fileUrl = get(uploadedFile, 'data.uploadFile');

    if (fileUrl) {
      this.setState({
        uploading: false,
        hover: false,
      });
      this.setGlobalUrlState(fileUrl);
    } else {
      this.setState({
        error: <SectionMessage appearance="warning">Nepovedlo se nahrát soubor.</SectionMessage>,
        uploading: false,
        hover: false,
      });
    }
  }

  render() {
    const { width, height, isInvalid, photoName } = this.props;

    return (
      <Field name="favourite-color" defaultValue="">
        {() => {
          if (this.state.uploading) {
            return (
              <div>
                <Spinner />
              </div>
            );
          }

          if (this.state.url) {
            return (
              <div>
                <div
                  style={{
                    padding: 10,
                    background: '#FAFBFC',
                    borderRadius: 3,
                    marginBottom: 10,
                    border: '2px solid #DDDFE4',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '300px' }}>
                    <Img
                      src={this.state.url}
                      alt="Náhled nahraného obrázku."
                      style={{ width: width || 300 }}
                      doNotReplaceURL={true}
                    />
                  </div>
                  <ButtonGroup>
                    <a href={this.state.url} target="_blank" rel="noopener noreferrer">
                      <Button iconBefore={<Icon name="download" />}>Stáhnout a otevřít</Button>
                    </a>
                    <Button
                      appearance="danger"
                      onClick={() => {
                        this.setGlobalUrlState('');
                      }}
                      iconBefore={<Icon name="trash" color="#ffffff" />}
                    >
                      Odebrat soubor
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );
          }

          return (
            <div>
              {this.state.error && <div style={{ marginBottom: 10 }}>{this.state.error}</div>}
              <div
                style={{
                  background: this.state.hover ? '#EBECF0' : '#FAFBFC',
                  border: `2px ${
                    isInvalid ? 'dashed #DE360B' : this.state.hover ? 'solid #4C9AFF' : 'dashed rgb(223, 225, 230)'
                  }`,
                  borderRadius: 3,
                }}
                onDragEnter={() => this.toggleHover()}
                onDragLeave={() => this.toggleHover()}
              >
                <Files
                  className="files-dropzone"
                  onChange={(items: string[]) => this.fileUpload(items, photoName)}
                  onError={() => this.setState({ error: true })}
                  accepts={['image/png', 'image/jpg', 'image/jpeg']}
                  multiple={false}
                  maxFiles={1}
                  minFileSize={0}
                  clickable
                >
                  <div
                    style={{
                      height: height || 100,
                      width: width || '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: 10,
                    }}
                  >
                    Přetáhněte soubor s obrázkem ve formátu PNG, JPG nebo JPEG.
                  </div>
                </Files>
              </div>
            </div>
          );
        }}
      </Field>
    );
  }
}

export default FileField;
