import React from 'react';
import styled from 'styled-components';

const Label = (props: { isRequired?: boolean; label: string }) => (
  <Wrapper>
    {props.label} {props.isRequired && <Required>*</Required>}
  </Wrapper>
);

const Wrapper = styled.div`
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3333333333333333;
  padding: 20px 0px 4px 0px;
`;

const Required = styled.span`
  color: #ff5630;
`;

export default Label;
