import React, { useState } from 'react';
import Img from 'react-cloudimage-responsive';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Icon from '../components/Icon';
import Page from '../components/Page';
import SearchInput, { searchData } from '../components/SearchInput';
import { useProvidersQuery } from '../graphql/types';

const ProvidersPage = () => {
  const navigate = useNavigate();
  const [needle, setNeedle] = useState('');
  const { loading, data, error } = useProvidersQuery();

  if (loading || error || !data || !data.providers) {
    return <Page title="Dodavatelé" isLoading={loading} isError={!!error} />;
  }

  const search = searchData(data.providers, ['name', 'users.email'], needle);

  return (
    <Page
      title="Dodavatelé"
      actions={
        <ButtonGroup>
          <LoadingButton
            iconBefore={<Icon name="edit" />}
            isLoading={loading}
            onClick={() => navigate(`/providers/add`)}
          >
            Přidat dodavatele
          </LoadingButton>
        </ButtonGroup>
      }
    >
      <SearchInput
        value={needle}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNeedle(e.target.value)}
        onSubmit={() => navigate(`/providers/${search[0].id}`)}
        placeholder="Zadejte jméno nebo email dodavatele a stiskněte Enter"
      />
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'image', content: 'Obrázek', width: '90px' },
            { key: 'name', content: 'Název' },
            { key: 'published', content: 'Stav' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          search &&
          search.map((provider) => ({
            cells: [
              {
                key: 'image',
                content: (
                  <div style={{ width: 75, height: 75 }}>
                    <Img
                      src={provider.image}
                      params={{
                        func: 'crop',
                        w: 75,
                        h: 75,
                      }}
                      alt={provider.name}
                      doNotReplaceURL={false}
                    />
                  </div>
                ),
              },
              { key: 'name', content: provider.name },
              {
                key: 'published',
                content: (
                  <Lozenge appearance={provider.published ? 'success' : 'default'}>
                    {provider.published ? 'Publikováno' : 'Koncept'}
                  </Lozenge>
                ),
              },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      onClick={() => navigate(`/providers/${provider.id}`)}
                      iconBefore={<Icon name="opened-folder" />}
                    >
                      Zobrazit detail
                    </Button>
                    <a style={{alignSelf: "center", paddingTop: "5px", paddingLeft: "5px"}} href={`https://svatba.cz/dodavatel/${provider.slug}`} target='_blank' rel="noreferrer"><Icon name="globe" /></a>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={search.length > 15 ? 15 : undefined}
        isLoading={loading}
        emptyView={<div>Žádní dodavatelé.</div>}
      />
    </Page>
  );
};

export default ProvidersPage;
