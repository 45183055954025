import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import Flex from '../components/Flex';
import Page from '../components/Page';
import TextField from '../components/TextField';
import { PROVIDER_QUERY } from '../graphql/graphqlQueries';
import { useCreateVideoMutation, useUpdateVideoMutation, useVideoQuery } from '../graphql/types';

const CreateAndEditProductSchema = Yup.object().shape({
  link: Yup.string().url('Zadejte platnou URL adresu videa.').required('Toto pole je povinné.'),
  type: Yup.string().required('Vyberte typ videa.'),
  priority: Yup.number().integer('Pouze celé číslo.').positive('Pouze kladné celé číslo.').nullable(),
});

const CreateProductPage = () => {
  const navigate = useNavigate();
  const { providerId } = useParams<'providerId'>();
  const { videoId } = useParams<'videoId'>();
  const videoIdParam = videoId !== 'add' ? videoId : undefined;
  const videoQuery = useVideoQuery({ variables: { id: videoIdParam + '' }, skip: !videoIdParam });
  const [executeCreateVideoMutation, createVideoMutation] = useCreateVideoMutation();
  const [executeUpdateVideoMutation, updateVideoMutation] = useUpdateVideoMutation();

  if (videoIdParam && videoQuery.called && !videoQuery.data) {
    return <Page title="Upravit video" isLoading={videoQuery.loading} isError={!!videoQuery.error} />;
  }

  if (
    (createVideoMutation && createVideoMutation.data && getValue(createVideoMutation.data, 'createVideo.id')) ||
    (updateVideoMutation && updateVideoMutation.data && getValue(updateVideoMutation.data, 'updateVideo.id'))
  ) {
    navigate(`/providers/${providerId}`);
  }

  const videoTypes = [
    {
      label: 'YouTube',
      value: 'YOUTUBE',
    },
    {
      label: 'Vimeo',
      value: 'VIMEO',
    },
  ];

  return (
    <Page title={!videoIdParam ? 'Přidat video' : 'Upravit video'}>
      <Formik
        initialValues={{
          link: videoIdParam ? getValue(videoQuery, 'data.video.link') : '',
          type: videoIdParam ? getValue(videoQuery, 'data.video.type') : '',
          priority: videoIdParam ? getValue(videoQuery, 'data.video.priority') : '',
        }}
        isInitialValid={!!videoIdParam}
        validationSchema={CreateAndEditProductSchema}
        validate={(values) => {
          let errors: { link?: string } = {};
          if (
            values.type === 'YOUTUBE' &&
            !['youtube.com', 'youtu.be'].some((substring) => values.link.includes(substring))
          ) {
            errors.link = 'Link videa musí obsahovat platnou URL adresu s youtube.com nebo youtu.be.';
          }

          if (values.type === 'VIMEO' && !values.link.includes('vimeo.com')) {
            errors.link = 'Link videa musí obsahovat platnou URL adresu s vimeo.com.';
          }

          return errors;
        }}
        onSubmit={(values) => {
          if (!videoIdParam) {
            executeCreateVideoMutation({
              variables: {
                providerId: providerId ?? '',
                link: values.link,
                type: values.type,
                priority: values.priority === '' ? null : Number.parseInt(values.priority),
              },
              refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
            });
          } else {
            executeUpdateVideoMutation({
              variables: {
                id: videoId ?? '',
                link: values.link,
                type: values.type,
                priority: values.priority === '' ? null : Number.parseInt(values.priority),
              },
              refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {(createVideoMutation.error || updateVideoMutation.error) && (
              <SectionMessage appearance="error">Nepovedlo se video uložit.</SectionMessage>
            )}
            <Flex>
              <TextField formikProps={props} label="Odkaz" name="link" autoFocus={!videoIdParam} isRequired />
              <Card title="Typ videa">
                <Select
                  options={videoTypes}
                  name="type"
                  value={videoTypes.find((type) => type.value === props.values.type)}
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('type', item.value);
                  }}
                  isSearchable={true}
                  placeholder="Vyberte typ videa"
                />
              </Card>
              <TextField formikProps={props} label="Priorita" name="priority" type="number" />
            </Flex>

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createVideoMutation.loading || updateVideoMutation.loading}
                isDisabled={!props.isValid || createVideoMutation.loading || updateVideoMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateProductPage;
