import moment from 'moment';
import React, { useState } from 'react';
import Img from 'react-cloudimage-responsive';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Icon from '../components/Icon';
import Page from '../components/Page';
import SearchInput, { searchData } from '../components/SearchInput';
import { POSTS_QUERY } from '../graphql/graphqlQueries';
import { DeletePostComponent, usePostsQuery } from '../graphql/types';

const PostsPage = () => {
  const navigate = useNavigate();
  const [needle, setNeedle] = useState('');
  const { loading, data, error } = usePostsQuery();

  if (loading || error || !data) {
    return <Page title="Příspěvky" isLoading={loading} isError={!!error} />;
  }

  const search = searchData(data.posts, ['name'], needle);

  return (
    <Page
      title="Příspěvky"
      actions={
        <ButtonGroup>
          <Button iconBefore={<Icon name="plus" />} onClick={() => navigate('/posts/add')}>
            Přidat článek
          </Button>
        </ButtonGroup>
      }
    >
      <SearchInput
        value={needle}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNeedle(e.target.value)}
        onSubmit={() => navigate(`/posts/${search[0].id}`)}
        placeholder="Zadejte název článku a stiskněte Enter"
      />
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'image', content: 'Obrázek', width: '90px' },
            { key: 'name', content: 'Název' },
            { key: 'publishedAt', content: 'Publikováno dne' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          search &&
          search.map((post) => ({
            cells: [
              {
                key: 'image',
                content: (
                  <div style={{ width: 75, height: 75 }}>
                    <Img
                      src={post.image}
                      alt={post.name}
                      params={{
                        func: 'crop',
                        w: 75,
                        h: 75,
                      }}
                      doNotReplaceURL={false}
                    />
                  </div>
                ),
              },
              { key: 'name', content: post.name },
              {
                key: 'publishedAt',
                content: (
                  <Lozenge appearance={post.publishedAt ? 'success' : 'default'}>
                    {post.publishedAt ? moment(post.publishedAt).format('D. M. YYYY').toString() : 'Koncept'}
                  </Lozenge>
                ),
              },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      appearance="primary"
                      onClick={() => navigate(`/posts/${post.id}`)}
                      iconBefore={<Icon name="edit" color="#ffffff" />}
                    >
                      Editovat
                    </Button>
                    <DeletePostComponent refetchQueries={[{ query: POSTS_QUERY }]}>
                      {(deletePost, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete smazat tuto položku?'))
                              deletePost({
                                variables: { id: post.id },
                              });
                          }}
                        />
                      )}
                    </DeletePostComponent>
                    <a style={{alignSelf: "center", paddingTop: "5px"}} href={`https://svatba.cz/blog/nahled/${post.slug}/${post.id}`} target='_blank' rel="noreferrer"><Icon name="globe" /></a>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        rowsPerPage={search.length > 15 ? 15 : undefined}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádné příspěvky.</div>}
      />
    </Page>
  );
};

export default PostsPage;
