import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import SectionMessage from '@atlaskit/section-message';
import Card from '../components/Card';
import Flex from '../components/Flex';
import Page from '../components/Page';
import TextField from '../components/TextField';
import { USERS_QUERY } from '../graphql/graphqlQueries';
import { useCreateUserMutation, useUpdateUserMutation, useUserQuery } from '../graphql/types';

const CreateAndEditProductSchema = Yup.object().shape({
  firstName: Yup.string().min(2, 'Zadejte alespoň dva znaky.').required('Toto pole je povinné.'),
  lastName: Yup.string().min(2, 'Zadejte alespoň dva znaky.').required('Toto pole je povinné.'),
  email: Yup.string().email('Zadejte platnou emailovou adresu.').required('Toto pole je povinné.'),
  admin: Yup.boolean().required(),
});

const CreateUserPage = () => {
  const { userId } = useParams<'userId'>();
  const userQuery = useUserQuery({ variables: { id: userId + '' }, skip: !userId });
  const [executeCreateUserMutation, createUserMutation] = useCreateUserMutation();
  const [executeUpdateUserMutation, updateUserMutation] = useUpdateUserMutation();
  const navigate = useNavigate();

  if (userId && userQuery.called && !userQuery.data) {
    return <Page isLoading={userQuery.loading} isError={!!userQuery.error} />;
  }

  if (
    (createUserMutation && createUserMutation.data && getValue(createUserMutation.data, 'createUser.id')) ||
    (updateUserMutation && updateUserMutation.data && getValue(updateUserMutation.data, 'updateUser.id'))
  ) {
    navigate('/users');
  }

  console.log(getValue(userQuery, 'data.user.admin'));

  return (
    <Page title={!userId ? 'Přidat uživatele' : 'Upravit uživatele'}>
      <Formik
        initialValues={{
          firstName: userId ? getValue(userQuery, 'data.user.firstName') : '',
          lastName: userId ? getValue(userQuery, 'data.user.lastName') : '',
          email: userId ? getValue(userQuery, 'data.user.email') : '',
          admin: userId ? getValue(userQuery, 'data.user.admin') : false,
        }}
        isInitialValid={!!userId}
        validationSchema={CreateAndEditProductSchema}
        onSubmit={(values) => {
          if (!userId) {
            executeCreateUserMutation({
              variables: values,
              refetchQueries: [{ query: USERS_QUERY }],
              awaitRefetchQueries: true,
            });
          } else {
            executeUpdateUserMutation({
              variables: { ...values, id: userId },
              refetchQueries: [{ query: USERS_QUERY }],
              awaitRefetchQueries: true,
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {(createUserMutation.error || updateUserMutation.error) && (
              <SectionMessage appearance="error">Nepovedlo se uživatele uložit.</SectionMessage>
            )}
            <Flex>
              <TextField formikProps={props} label="Křestní jméno" name="firstName" autoFocus={!userId} isRequired />
              <TextField formikProps={props} label="Příjmení" name="lastName" isRequired />
              <TextField formikProps={props} label="Email" name="email" isRequired />
              <Card title="Role">
                <div style={{ marginTop: 8 }}>
                  <Checkbox
                    isChecked={props.values.admin}
                    onChange={() => props.setFieldValue('admin', !props.values.admin)}
                    label="Administrátor"
                  />
                </div>
              </Card>
            </Flex>

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createUserMutation.loading || updateUserMutation.loading}
                isDisabled={!props.isValid || createUserMutation.loading || updateUserMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateUserPage;
