import gql from 'graphql-tag';

export const ME_QUERY = gql`
  query me {
    me {
      id
    }
  }
`;

export const PHOTO_QUERY = gql`
  query photo($id: ID!) {
    photo(id: $id) {
      id
      name
      link
      createdAt
      showInInspiration
      showInGallery
      priorityGallery
      priorityInspiration
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      provider {
        id
        name
        slug
      }
    }
  }
`;

export const PHOTOS_QUERY = gql`
  query photos {
    photos {
      id
      name
      link
      createdAt
      showInInspiration
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      provider {
        id
        name
      }
    }
  }
`;

export const INSPIRATION_PHOTOS_QUERY = gql`
  query inspirationPhotos {
    photos(onlyInspiration: true) {
      id
      name
      link
      showInInspiration
      categories {
        id
        name
      }
      provider {
        id
        name
      }
    }
  }
`;

export const PROVIDER_QUERY = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      oldId
      cashback
      name
      slug
      registrationNumber
      taxRegistrationNumber
      description
      contactEmail
      socialTwitter
      socialFacebook
      socialPinterest
      socialInstagram
      socialYoutube
      membershipFrom
      membershipTo
      priority
      published
      image
      imageCropPosition
      userLikesCount
      videos {
        id
        link
        type
        priority
      }
      stores {
        id
        name
        address
        priority
        city {
          id
          name
        }
      }
      salesman {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
      category {
        id
        name
      }
      tags {
        id
        name
      }
      products {
        id
        name
        price
        priority
        photo {
          id
          name
          link
        }
      }
      services {
        id
        name
        priceFrom
        priceTo
        priority
        description
      }
      users {
        id
        firstName
        lastName
        email
      }
      photos {
        id
        name
        link
        showInInspiration
        showInGallery
        priorityInspiration
        priorityGallery
        categories {
          id
          name
        }
        tags {
          id
          name
        }
      }
      ratings {
        id
        numberOfStars
        content
        providerFeedback
        feedbackAt
        userName
        user {
          id
          firstName
          lastName
        }
      }
      plannerUsers {
        id
        firstName
        lastName
      }
    }
  }
`;

export const PROVIDERS_QUERY = gql`
  query providers {
    providers(includeDrafts: true) {
      id
      name
      image
      published
      users {
        email
      }
    }
  }
`;

export const STATS = gql`
  query providerAnalytics($providerSlug: String!) {
    providerAnalytics(providerSlug: $providerSlug) {
      clicked_gps
      dial_email
      go_to_about
      go_to_contacts
      go_to_facebook
      go_to_gallery
      go_to_instagram
      go_to_products
      go_to_ratings
      go_to_services
      go_to_twitter
      go_to_videos
      go_to_web
      go_to_youtube
      hide_message_form
      play_video
      send_email
      show_email
      show_less_product_info
      show_message_form
      show_message_form_sidebar
      show_more_contacts
      show_more_product_info
      show_phone
      show_photo
      show_web
      visit
    }
  }
`;

export const CATEGORIES_QUERY = gql`
  query categories {
    categories {
      id
      name
      filters {
        id
        name
        tags {
          id
          name
        }
      }
    }
  }
`;

export const CATEGORIES_PRICE_QUERY = gql`
  query categoriesPrice {
    categories {
      id
      name
      slug
      priceStart
      priceStandard
      priceExtra
      pricePremium
    }
  }
`;

export const CATEGORY_QUERY = gql`
  query category($categorySlug: String!) {
    category(slug: $categorySlug) {
      id
      name
      slug
      priceStart
      priceStandard
      priceExtra
      pricePremium
    }
  }
`;

export const FILTERS_QUERY = gql`
  query filters {
    filters {
      id
      name
      activeForProvider
      activeForInspiration
      categories {
        id
      }
      tags {
        id
        name
        slug
      }
    }
  }
`;

export const POSTS_QUERY = gql`
  query posts {
    posts(includeDrafts: true) {
      id
      name
      image
      slug
      publishedAt
    }
  }
`;

export const POST_QUERY = gql`
  query post($id: ID!) {
    post(id: $id) {
      id
      name
      slug
      image
      imageAlt
      publishedAt
      perex
      content
      category {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const USERS_QUERY = gql`
  query users($adminOnly: Boolean) {
    users(adminOnly: $adminOnly) {
      id
      firstName
      lastName
      email
      admin
      providers {
        id
        name
      }
    }
  }
`;

export const USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      admin
    }
  }
`;

export const PRODUCT_QUERY = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      name
      priority
      price
      description
      link
      photo {
        id
      }
    }
  }
`;

export const SERVICE_QUERY = gql`
  query service($id: ID!) {
    service(id: $id) {
      id
      name
      priceFrom
      priceTo
      priority
      description
    }
  }
`;

export const VIDEO_QUERY = gql`
  query video($id: ID!) {
    video(id: $id) {
      id
      link
      type
      priority
    }
  }
`;

export const STORE_QUERY = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      name
      address
      lat
      lng
      phone
      web
      email
      openingHours
      priority
      zipCode
      city {
        id
      }
      provider {
        id
      }
    }
  }
`;

export const RATING_QUERY = gql`
  query rating($id: ID!) {
    rating(id: $id) {
      id
      numberOfStars
      userName
      content
      providerFeedback
      feedbackAt
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const CITIES_QUERY = gql`
  query cities {
    cities {
      id
      name
    }
  }
`;

export const REGIONS_QUERY = gql`
  query regions {
    regions {
      id
      name
      shortName
      priceMultiplier
    }
  }
`;

export const REGION_QUERY = gql`
  query region($id: ID!) {
    region(id: $id) {
      name
      shortName
      priceMultiplier
    }
  }
`;

export const PRICE_ITEMS_QUERY = gql`
  query priceItems {
    priceItems {
      id
      name
      price
      priority
    }
  }
`;
