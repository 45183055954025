import { Formik } from 'formik';
import React from 'react';
import { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import TextField from '@atlaskit/textfield';
import LoginWrapper from '../components/LoginWrapper';
import { useLoginMutation } from '../graphql/types';

const LoginPage = () => {
  const [login, { error, data, loading }] = useLoginMutation();

  if (loading) {
    return (
      <LoginWrapper>
        <h1 style={{ marginBottom: 50 }}>Přihlášení</h1>
        <Spinner size="large" />
      </LoginWrapper>
    );
  }

  if (data) {
    return (
      <LoginWrapper>
        <h1 style={{ marginBottom: 50 }}>Přihlášení</h1>
        <SectionMessage appearance="success">
          Poslali jsme vám přihlašovací email. Toto okno můžete zavřít a pokračovat dle intrukcí v e-mailu.
        </SectionMessage>
      </LoginWrapper>
    );
  }

  return (
    <LoginWrapper>
      <h1>Přihlášení</h1>
      {error && (
        <div style={{ marginTop: 50 }}>
          <SectionMessage appearance="error">
            Uživatel s vámi zadanou emailovou adresou neexistuje nebo nemá oprávnění administrátora.
          </SectionMessage>
        </div>
      )}
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async (values) => {
          await login({ variables: { ...values, adminPanel: true } });
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
            <TextField
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.email}
              name="email"
              label="Email"
              required
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 20 }}>
              <LoadingButton appearance="primary" type="submit" isLoading={loading}>
                Přihlásit se
              </LoadingButton>
            </div>
          </form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default LoginPage;
