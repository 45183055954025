import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Icon from '../components/Icon';
import Page from '../components/Page';
import SearchInput, { searchData } from '../components/SearchInput';
import { useUsersQuery } from '../graphql/types';

const ProvidersPage = () => {
  const navigate = useNavigate();

  return (
    <Page
      title="Uživatelé"
      actions={
        <ButtonGroup>
          <LoadingButton iconBefore={<Icon name="edit" />} onClick={() => navigate(`/users/add`)}>
            Přidat uživatele
          </LoadingButton>
        </ButtonGroup>
      }
    >
      <Button onClick={() => navigate(`/usersShow`)}>Zobrazit uživatele</Button>
    </Page>
  );
};

export default ProvidersPage;
