import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import React from 'react';
import Img from 'react-cloudimage-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import Flex from '../components/Flex';
import Page from '../components/Page';
import Textarea from '../components/Textarea';
import TextField from '../components/TextField';
import { PRODUCT_QUERY, PROVIDER_QUERY } from '../graphql/graphqlQueries';
import {
  useCreateProductMutation,
  useProductQuery,
  useProviderQuery,
  useUpdateProductMutation,
} from '../graphql/types';
import { LoadingButton } from '@atlaskit/button';

const CreateAndEditProductSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  price: Yup.number().integer('Pouze celé číslo.').nullable(),
  description: Yup.string().min(2, 'Zadejte minimálně 2 znaky').required('Toto pole je povinné.'),
  priority: Yup.number().integer('Pouze celé číslo.').positive('Pouze kladné celé číslo.').nullable(),
  link: Yup.string().url('Zadejte platnou URL adresu.').required('Toto pole je povinné.'),
});

const CreateProductPage = () => {
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const productIdParam = productId !== 'add' ? productId : undefined;
  const { providerId } = useParams<'providerId'>();

  const productQuery = useProductQuery({ variables: { id: productIdParam + '' }, skip: !productIdParam });
  const providerQuery = useProviderQuery({ variables: { id: providerId ?? '' } });
  const [executeCreateProductMutation, createProductMutation] = useCreateProductMutation();
  const [executeUpdateProductMutation, updateProductMutation] = useUpdateProductMutation();

  if (productIdParam && productQuery.called && !productQuery.data) {
    return <Page title="Upravit produkt" isLoading={productQuery.loading} isError={!!productQuery.error} />;
  }

  if (
    (createProductMutation && createProductMutation.data && getValue(createProductMutation.data, 'createProduct.id')) ||
    (updateProductMutation && updateProductMutation.data && getValue(updateProductMutation.data, 'updateProduct.id'))
  ) {
    navigate(`/providers/${providerId}`);
  }

  return (
    <Page title={!productIdParam ? 'Přidat produkt' : 'Upravit produkt'}>
      <Formik
        initialValues={{
          name: productIdParam ? getValue(productQuery, 'data.product.name') : '',
          link: productIdParam ? getValue(productQuery, 'data.product.link') : '',
          description: productIdParam ? getValue(productQuery, 'data.product.description') : '',
          price: productIdParam ? getValue(productQuery, 'data.product.price') : '',
          priority: productIdParam ? getValue(productQuery, 'data.product.priority') : '',
          photoId: productIdParam ? getValue(productQuery, 'data.product.photo.id') : '',
        }}
        isInitialValid={!!productIdParam}
        validationSchema={CreateAndEditProductSchema}
        onSubmit={(values) => {
          if (productIdParam) {
            executeUpdateProductMutation({
              variables: {
                ...values,
                price: values.price === '' ? null : values.price,
                priority: values.priority === '' ? null : values.priority,
                id: productIdParam,
              },
              refetchQueries: [
                { query: PROVIDER_QUERY, variables: { id: providerId } },
                { query: PRODUCT_QUERY, variables: { id: productIdParam } },
              ],
            });
          } else {
            executeCreateProductMutation({
              variables: {
                ...values,
                price: values.price === '' ? null : values.price,
                priority: values.priority === '' ? null : values.priority,
                providerId: providerId ?? '',
              },
              refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {(createProductMutation.error || updateProductMutation.error) && (
              <SectionMessage appearance="error">Nepovedlo se produkt uložit.</SectionMessage>
            )}
            <Flex>
              <TextField formikProps={props} label="Název" name="name" autoFocus={!productIdParam} isRequired />
              <TextField formikProps={props} label="Odkaz na produkt" name="link" isRequired />
              <TextField
                formikProps={props}
                label="Cena (cena od záporná)"
                name="price"
                type="number"
                elemAfterInput={<div style={{ marginRight: 10 }}>Kč</div>}
              />
              <TextField formikProps={props} label="Priorita" name="priority" type="number" />
              <Card title="Obrázek">
                <Select
                  options={
                    providerQuery.data && providerQuery.data.provider.photos
                      ? [
                          ...providerQuery.data.provider.photos.map((photo) => ({
                            label: photo.name,
                            value: photo.id,
                          })),
                        ]
                      : {}
                  }
                  name="photoId"
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('photoId', item.value);
                  }}
                  value={
                    providerQuery.data &&
                    providerQuery.data.provider &&
                    providerQuery.data.provider.photos &&
                    providerQuery.data.provider.photos
                      .map((photo) => ({ label: photo.name, value: photo.id }))
                      .find((photo) => photo.value === props.values.photoId)
                  }
                  isSearchable={true}
                  placeholder="Vyberte obrázek"
                  formatOptionLabel={(option: { value: string; label: string }) => {
                    const photoId = option.value;

                    const photo =
                      providerQuery.data && providerQuery.data.provider.photos
                        ? providerQuery.data.provider.photos.find((photo) => photo.id === photoId)
                        : null;

                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ width: 50, height: 50 }}>
                          <Img
                            src={photo ? photo.link : undefined}
                            alt=""
                            params={{
                              func: 'crop',
                              w: 50,
                              h: 50,
                            }}
                            doNotReplaceURL={false}
                          />
                        </div>
                        <span
                          style={{
                            paddingLeft: 8,
                            paddingBottom: 0,
                          }}
                        >
                          {option.label}
                        </span>
                      </div>
                    );
                  }}
                />
              </Card>
              <Textarea formikProps={props} label="Popis" name="description" minimumRows={5} />
            </Flex>

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createProductMutation.loading || updateProductMutation.loading}
                isDisabled={!props.isValid || createProductMutation.loading || updateProductMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateProductPage;
