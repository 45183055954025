import FuzzySearch from 'fuzzy-search';
import React from 'react';

export const searchData = (data: any[], searchKeys: string[], needle: string) => {
  const searcher = new FuzzySearch(data, searchKeys, {
    sort: true,
  });

  return searcher.search(needle);
};

const SearchInput = (props: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  placeholder: string;
}) => (
  <form onSubmit={props.onSubmit} style={{ position: 'relative', display: 'flex' }}>
    <img
      src="https://img.icons8.com/ios-glyphs/60/f7bfb5/search.png"
      style={{ position: 'absolute', left: 15, top: 20, width: 30, height: 30 }}
      alt="Search Icon"
    />
    <input
      value={props.value}
      onChange={props.onChange}
      autoFocus={true}
      placeholder={props.placeholder}
      style={{
        flex: '1 1 100%',
        padding: '20px 20px 20px 60px',
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderRadius: 10,
        border: '2px solid #f7bfb5',
        outline: 'none',
        marginBottom: 25,
        fontSize: 20,
      }}
    />
  </form>
);

export default SearchInput;
