import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@atlaskit/button';
import { DatePicker } from '@atlaskit/datetime-picker';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import Flex from '../components/Flex';
import Label from '../components/Label';
import Page from '../components/Page';
import Textarea from '../components/Textarea';
import TextField from '../components/TextField';
import { PROVIDER_QUERY, RATING_QUERY } from '../graphql/graphqlQueries';
import { useCreateRatingMutation, useRatingQuery, useUpdateRatingMutation } from '../graphql/types';

const CreateAndEditRatingSchema = Yup.object().shape({
  userName: Yup.string().min(1).nullable(),
  content: Yup.string().required(),
  numberOfStars: Yup.number().min(1).max(5).integer().required(),
  providerFeedback: Yup.string().nullable(),
  createdAt: Yup.date().nullable(),
});

const CreateRatingPage = () => {
  const navigate = useNavigate();
  const { ratingId } = useParams<'ratingId'>();
  const { providerId } = useParams<'providerId'>();

  const ratingIdParam = ratingId !== 'add' ? ratingId : undefined;
  const ratingQuery = useRatingQuery({ variables: { id: ratingIdParam + '' }, skip: !ratingIdParam });
  const [executeCreateRatingMutation, createRatingMutation] = useCreateRatingMutation({
    refetchQueries: [
      { query: PROVIDER_QUERY, variables: { id: providerId } },
      { query: RATING_QUERY, variables: { id: ratingIdParam } },
    ],
  });
  const [executeUpdateRatingMutation, updateRatingMutation] = useUpdateRatingMutation({
    refetchQueries: [
      { query: PROVIDER_QUERY, variables: { id: providerId } },
      { query: RATING_QUERY, variables: { id: ratingIdParam } },
    ],
  });

  if (ratingIdParam && ratingQuery.called && !ratingQuery.data) {
    return <Page title="Upravit hodnocení" isLoading={ratingQuery.loading} isError={!!ratingQuery.error} />;
  }

  if (
    (createRatingMutation &&
      createRatingMutation.data &&
      getValue(createRatingMutation.data, 'adminCreateRating.id')) ||
    (updateRatingMutation && updateRatingMutation.data && getValue(updateRatingMutation.data, 'updateRating.id'))
  ) {
    navigate(`/providers/${providerId}`);
  }

  const starTypes = [
    {
      label: '1 hvězda',
      value: 1,
    },
    {
      label: '2 hvězdy',
      value: 2,
    },
    {
      label: '3 hvězdy',
      value: 3,
    },

    {
      label: '4 hvězdy',
      value: 4,
    },
    {
      label: '5 hvězd',
      value: 5,
    },
  ];

  return (
    <Page title={!ratingIdParam ? 'Přidat hodnocení' : 'Upravit hodnocení'}>
      <Formik
        initialValues={{
          userName: ratingIdParam ? getValue(ratingQuery, 'data.rating.userName') : '',
          content: ratingIdParam ? getValue(ratingQuery, 'data.rating.content') : '',
          numberOfStars: ratingIdParam ? getValue(ratingQuery, 'data.rating.numberOfStars') : 5,
          providerFeedback: ratingIdParam ? getValue(ratingQuery, 'data.rating.providerFeedback') : '',
          createdAt: ratingIdParam ? getValue(ratingQuery, 'data.rating.createdAt') : '',
        }}
        isInitialValid={!!ratingIdParam}
        validationSchema={CreateAndEditRatingSchema}
        onSubmit={(values) => {
          if (ratingIdParam) {
            executeUpdateRatingMutation({
              variables: {
                userName:
                  ratingQuery.data &&
                  ratingQuery.data.rating &&
                  ratingQuery.data.rating.user &&
                  ratingQuery.data.rating.user.id
                    ? undefined
                    : values.userName,
                content: values.content,
                numberOfStars: values.numberOfStars,
                createdAt:
                  ratingQuery.data &&
                  ratingQuery.data.rating &&
                  ratingQuery.data.rating.user &&
                  ratingQuery.data.rating.user.id
                    ? undefined
                    : values.createdAt,
                providerFeedback:
                  ratingQuery.data && ratingQuery.data.rating && ratingQuery.data.rating.providerFeedback
                    ? values.providerFeedback
                    : undefined,
                id: ratingIdParam,
              },
            });
          } else {
            executeCreateRatingMutation({
              variables: {
                userName: values.userName,
                content: values.content,
                numberOfStars: values.numberOfStars,
                createdAt: values.createdAt,
                provider: providerId ?? '',
              },
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {(createRatingMutation.error || updateRatingMutation.error) && (
              <SectionMessage appearance="error">Nepovedlo se hodnocení uložit.</SectionMessage>
            )}
            <Flex>
              {ratingIdParam &&
              ratingQuery.data &&
              ratingQuery.data.rating &&
              ratingQuery.data.rating.user &&
              ratingQuery.data.rating.user.id ? (
                <div>
                  <Label label="Jméno a příjmení" />
                  <div style={{ marginTop: 5 }}>
                    {ratingQuery.data.rating.user.firstName} {ratingQuery.data.rating.user.lastName}
                  </div>
                </div>
              ) : (
                <TextField
                  formikProps={props}
                  label="Jméno a příjmení"
                  name="userName"
                  autoFocus={!ratingIdParam}
                  isRequired
                />
              )}
              <Card title="Počet hvězdiček">
                <Select
                  options={starTypes}
                  name="type"
                  value={starTypes.find((type) => type.value === props.values.numberOfStars)}
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('numberOfStars', item.value);
                  }}
                  isSearchable={true}
                  placeholder="Vyberte hodnocení"
                />
              </Card>

              {(!ratingIdParam ||
                (ratingQuery.data && ratingQuery.data.rating && ratingQuery.data.rating.userName)) && (
                <div>
                  <Label label="Datum hodnocení" />
                  <DatePicker
                    value={props.values.createdAt}
                    onChange={(value) => props.setFieldValue('createdAt', moment(value).utc().toISOString())}
                    locale="cs_CZ"
                  />
                </div>
              )}
            </Flex>

            <Textarea formikProps={props} isRequired={true} name="content" label="Slovní hodnocení" minimumRows={6} />

            {ratingIdParam && ratingQuery.data && ratingQuery.data.rating.providerFeedback && (
              <Textarea
                formikProps={props}
                isRequired={true}
                name="providerFeedback"
                label="Reakce dodavatele"
                minimumRows={6}
              />
            )}

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createRatingMutation.loading || updateRatingMutation.loading}
                isDisabled={!props.isValid || createRatingMutation.loading || updateRatingMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateRatingPage;
