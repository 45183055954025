import 'react-toastify/dist/ReactToastify.css';
import useAxios from 'axios-hooks';
import moment from 'moment';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Icon from '../components/Icon';
import Page from '../components/Page';

const productionUrl = 'svatba.cz';

const refreshPage = () => window.location.reload();

const PostsPage = () => {
  const [domainsResponse] = useAxios({
    url: `https://api.zeit.co/v3/aliases/${productionUrl}?teamId=team_hQA3LC55nyZafEdCnQRsAPH2`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer aVtJo63uIQAeQ5nZrH6Ke2Xx',
    },
  });

  let productionBuild = domainsResponse && domainsResponse.data && domainsResponse.data.deploymentId;

  const [redeployResponse, redeploy] = useAxios(
    {
      url: 'https://api.vercel.com/v1/integrations/deploy/QmQhmuhosBWEyYwuat6vUqrBCbYcYPivEcFTtXmP65mgZH/AO80AQFntI',
    },
    { manual: true },
  );

  const [{ data, loading, error }] = useAxios({
    url: `https://api.vercel.com/v6/deployments`,
    method: 'GET',
    headers: {
      Authorization: 'Bearer aVtJo63uIQAeQ5nZrH6Ke2Xx',
    },
    params: {
      // limit: 1000,
      teamId: 'team_hQA3LC55nyZafEdCnQRsAPH2',
    },
  });

  const [deleteBuildResponse, deleteBuild] = useAxios(
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer aVtJo63uIQAeQ5nZrH6Ke2Xx',
      },
      params: {
        teamId: 'team_hQA3LC55nyZafEdCnQRsAPH2',
      },
    },
    { manual: true },
  );

  const [asignDomainResponse, asignDomain] = useAxios({}, { manual: true });

  if (deleteBuildResponse.error) {
    toast.error('Nepovedlo se smazat build.');
    deleteBuildResponse.error = null;
  }

  if (error) {
    toast.error('Nepovedlo se načíst aktuální buildy.');
    deleteBuildResponse.error = null;
  }

  if (redeployResponse.data && redeployResponse.response && redeployResponse.response.status === 201) {
    toast.success(
      'Zahajuji redeploy. Do jedné minuty se zobrazí nový build automaticky bez potřeby refreshovat stránku ve výpisu buildů.',
      {
        autoClose: 20000,
      },
    );
    toast.info('Stránka se sama automaticky refreshuje.');
    setTimeout(() => refreshPage(), 20000);
    redeployResponse.response.status = 0;
  }

  if (redeployResponse.error) {
    toast.error('Nepovedlo se vyvolat nový build.');
    redeployResponse.error = null;
  }

  if (asignDomainResponse.error) {
    toast.error('Nepovedlo se build nasadit na produkci.');
    asignDomainResponse.error = null;
  }

  if (asignDomainResponse.data && !asignDomainResponse.loading && !asignDomainResponse.error) {
    toast.success('Build přiřazen na produkci.');
    toast.info('Stránka se sama automaticky refreshuje.');
    asignDomainResponse.response = undefined;
    setTimeout(() => refreshPage(), 5000);
  }

  if (
    !deleteBuildResponse.error &&
    !deleteBuildResponse.loading &&
    deleteBuildResponse.data &&
    deleteBuildResponse.data.state === 'DELETED'
  ) {
    toast.success('Build byl úspěšně smazán.');
    toast.info('Stránka se sama automaticky refreshuje.');
    setTimeout(() => refreshPage(), 5000);
  }

  const builds = (data && data.deployments) || [];

  console.log(builds);

  return (
    <Page
      title="Buildy"
      actions={
        <ButtonGroup>
          <Button
            onClick={() => {
              refreshPage();
            }}
            iconBefore={<Icon name="refresh" color="#000000" />}
          >
            Refreshovat stránku
          </Button>
          <LoadingButton
            appearance="primary"
            isLoading={redeployResponse.loading}
            onClick={() => {
              redeploy();
            }}
            iconBefore={<Icon name="plus" color="#ffffff" />}
          >
            Nový build
          </LoadingButton>
        </ButtonGroup>
      }
    >
      <ToastContainer enableMultiContainer position={toast.POSITION.TOP_RIGHT} />
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'prod', content: 'Prod' },
            { key: 'status', content: 'Status' },
            { key: 'createdAt', content: 'Vytvořeno' },
            { key: 'author', content: 'Autor' },
            { key: 'branch', content: 'Branch' },
            { key: 'commit', content: 'Commit' },
            { key: 'actions', content: 'Akce', width: '140px' },
          ],
        }}
        rows={
          builds &&
          builds
            .filter((build: any) => build.name === 'svatba-react')
            .map((build: any) => ({
              cells: [
                {
                  key: 'repository',
                  content: build.uid === productionBuild && (
                    <div style={{ textAlign: 'center' }}>
                      <Lozenge appearance="success">
                        <Icon name="checkmark" color="#147353" size={12} />
                      </Lozenge>
                    </div>
                  ),
                },
                {
                  key: 'status',
                  content: (
                    <Lozenge
                      appearance={
                        build.state === 'READY'
                          ? 'success'
                          : build.state === 'BUILDING'
                          ? 'moved'
                          : build.state === 'ERROR'
                          ? 'removed'
                          : build.state === 'INITIALIZING'
                          ? 'moved'
                          : 'default'
                      }
                    >
                      {build.state}
                    </Lozenge>
                  ),
                },
                {
                  key: 'createdAt',
                  content: (
                    <span title={moment(build.created).format('DD. MM. YYYY hh:mm').toString()}>
                      {moment(build.created).fromNow()}
                    </span>
                  ),
                },
                {
                  key: 'author',
                  content: build.meta && build.meta.bitbucketCommitRef && (
                    <img
                      src={build.meta.bitbucketCommitAuthorAvatar}
                      alt={build.meta.bitbucketCommitAuthorName}
                      style={{ width: 25, height: 25, borderRadius: 100, marginLeft: 5 }}
                    />
                  ),
                },
                {
                  key: 'branch',
                  content: build.meta && build.meta.bitbucketCommitRef,
                },
                {
                  key: 'commit',
                  content: (
                    <div
                      style={{ width: 300, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      title={build.meta && build.meta.bitbucketCommitMessage}
                    >
                      {build.meta && build.meta.bitbucketCommitMessage}
                    </div>
                  ),
                },

                {
                  key: 'actions',
                  content: (
                    <ButtonGroup>
                      <a
                        href={`https://${build.url}`}
                        style={{ color: '#000 !important' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          title="Přejít na build"
                          appearance="primary"
                          isDisabled={build.state !== 'READY'}
                          iconBefore={
                            <Icon
                              name="link"
                              color={productionBuild === build.uid || build.state !== 'READY' ? '#b3bac5' : '#ffffff'}
                            />
                          }
                        />
                      </a>
                      <Button
                        title="Přepnout na produkci"
                        appearance="warning"
                        isDisabled={productionBuild === build.uid || build.state !== 'READY'}
                        onClick={() => {
                          if (window.confirm('Chcete skutečně tento build skutečně umístit na produkci?')) {
                            asignDomain({
                              url: `/v2/now/deployments/${build.uid}/aliases?teamId=team_hQA3LC55nyZafEdCnQRsAPH2`,
                              data: { alias: productionUrl },
                              method: 'POST',
                              headers: {
                                authorization: 'Bearer IIiKymsdAjYNWfDypvruWXy2',
                              },
                            });
                            asignDomain({
                              url: `/v2/now/deployments/${build.uid}/aliases?teamId=team_hQA3LC55nyZafEdCnQRsAPH2`,
                              data: { alias: `www.${productionUrl}` },
                              method: 'POST',
                              headers: {
                                authorization: 'Bearer IIiKymsdAjYNWfDypvruWXy2',
                              },
                            });
                          }
                        }}
                        iconBefore={
                          <Icon
                            name="change"
                            color={productionBuild === build.uid || build.state !== 'READY' ? '#b3bac5' : '#000000'}
                          />
                        }
                      >
                        Změnit produkci
                      </Button>
                      <Button
                        title="Smazat build"
                        appearance="danger"
                        isDisabled={productionBuild === build.uid || build.state === 'BUILDING'}
                        onClick={() => {
                          if (window.confirm('Chcete skutečně tento build smazat?')) {
                            deleteBuild({
                              url: `https://api.zeit.co/v11/now/deployments/${build.uid}`,
                            });
                          }
                        }}
                        iconBefore={
                          <Icon
                            name="delete"
                            color={productionBuild === build.uid || build.state === 'BUILDING' ? '#b3bac5' : '#ffffff'}
                          />
                        }
                      />
                    </ButtonGroup>
                  ),
                },
              ],
            }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={undefined}
        isLoading={loading}
        emptyView={<div>Žádné buildy.</div>}
      />
    </Page>
  );
};

export default PostsPage;
