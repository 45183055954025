import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import Icon from '../components/Icon';
import Page from '../components/Page';
import SearchInput, { searchData } from '../components/SearchInput';
import { useUsersQuery } from '../graphql/types';

const UsersPage = () => {
  const navigate = useNavigate();
  const [needle, setNeedle] = useState('');
  const { loading, data, error } = useUsersQuery();

  if (loading || error || !data || !data.users) {
    return <Page title="Uživatelé" isLoading={loading} isError={!!error} />;
  }

  const search = searchData(data.users, ['name', 'email'], needle);

  return (
    <Page
      title="Uživatelé"
      actions={
        <ButtonGroup>
          <LoadingButton iconBefore={<Icon name="edit" />} isLoading={loading} onClick={() => navigate(`/users/add`)}>
            Přidat uživatele
          </LoadingButton>
        </ButtonGroup>
      }
    >
      <SearchInput
        value={needle}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNeedle(e.target.value)}
        onSubmit={() => navigate(`/users/${search[0].id}`)}
        placeholder="Zadejte jméno nebo email uživatele a stiskněte Enter"
      />
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'name', content: 'Jméno' },
            { key: 'email', content: 'Email' },
            { key: 'admin', content: 'Admin' },
            { key: 'providers', content: 'Správce dodavatelů' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          search &&
          search.map((user) => ({
            cells: [
              { key: 'name', content: `${user.firstName} ${user.lastName}` },
              { key: 'email', content: user.email },
              {
                key: 'admin',
                content: <Lozenge appearance={user.admin ? 'success' : 'default'}>{user.admin ? 'Ano' : 'Ne'}</Lozenge>,
              },
              { key: 'providers', content: user.providers.map((provider: any) => provider.name).join(', ') },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      appearance="primary"
                      onClick={() => navigate(`/users/${user.id}`)}
                      iconBefore={<Icon name="opened-folder" color="#ffffff" />}
                    >
                      Editovat
                    </Button>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={search.length > 15 ? 15 : undefined}
        isLoading={loading}
        emptyView={<div>Žádní dodavatelé.</div>}
      />
    </Page>
  );
};

export default UsersPage;
