import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import moment from 'moment';
import Switch from 'rc-switch';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import slugify from 'slugify';
import * as Yup from 'yup';
import Button, { LoadingButton } from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import { DatePicker } from '@atlaskit/datetime-picker';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import FileField from '../components/FileField';
import Flex from '../components/Flex';
import GraphqlError from '../components/GraphqlError';
import Icon from '../components/Icon';
import Label from '../components/Label';
import Page from '../components/Page';
import Textarea from '../components/Textarea';
import TextField from '../components/TextField';
import { PROVIDER_QUERY, PROVIDERS_QUERY } from '../graphql/graphqlQueries';
import {
  useCategoriesQuery,
  useCreateProviderMutation,
  useFiltersQuery,
  useProviderQuery,
  useUpdateProviderMutation,
  useUsersQuery,
} from '../graphql/types';

const CreateAndEditProviderSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(50, 'Toto pole je dlouhé. Zadejte maximálně 50 znaků.')
    .required('Toto pole je povinné.'),
  slug: Yup.string()
    .trim()
    .min(2, 'Zadejte minimálně 2 znaky')
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Zadejte pouze malá písmena bez diakritiky, pomlčku (-) a čísla.')
    .required('Toto pole je povinné.'),
  registrationNumber: Yup.string().trim(),
  taxRegistrationNumber: Yup.string().trim(),
  contactEmail: Yup.string().trim().email(),
  description: Yup.string().min(2, 'Zadejte minimálně 2 znaky.').required(),
  socialTwitter: Yup.string().url(),
  socialFacebook: Yup.string().url(),
  socialPinterest: Yup.string().url(),
  socialInstagram: Yup.string().url(),
  socialYoutube: Yup.string().url(),
  priority: Yup.number().positive().integer(),
  cashback: Yup.number().positive().integer(),
  published: Yup.boolean().required(),
  membershipFrom: Yup.date().nullable(),
  membershipTo: Yup.date().nullable(),
  image: Yup.string().required().url(),
  imageCropPosition: Yup.string().required(),
  salesman: Yup.string().required(),
  category: Yup.string().required(),
  tags: Yup.array().min(1).required(),
});

const CreateProviderPage = () => {
  const navigate = useNavigate();
  const { providerId } = useParams<'providerId'>();
  const providerQuery = useProviderQuery({ variables: { id: providerId + '' }, skip: !providerId });
  const categoriesFetcher = useCategoriesQuery();
  const filtersFetcher = useFiltersQuery();
  const usersFetcher = useUsersQuery({ variables: { adminOnly: true } });
  const [executeUpdateProviderMutation, updateProviderMutation] = useUpdateProviderMutation();
  const [executeCreateProviderMutation, createProviderMutation] = useCreateProviderMutation();

  if (providerQuery.loading || filtersFetcher.loading || categoriesFetcher.loading) {
    return null;
  }

  if (
    createProviderMutation &&
    createProviderMutation.data &&
    getValue(createProviderMutation.data, 'createProvider.id')
  ) {
    navigate(`/providers/${createProviderMutation.data.createProvider.id}`);
  }

  if (
    updateProviderMutation &&
    updateProviderMutation.data &&
    getValue(updateProviderMutation.data, 'updateProvider.id')
  ) {
    navigate(`/providers/${providerId}`);
  }

  return (
    <Page
      title={!providerId ? 'Přidat dodavetele' : 'Upravit dodavatele'}
      isLoading={providerQuery.loading || filtersFetcher.loading || categoriesFetcher.loading}
      isError={!!providerQuery.error || !!filtersFetcher.error || !!categoriesFetcher.error}
      children={
        <Formik
          initialValues={{
            name: providerId ? getValue(providerQuery, 'data.provider.name') : '',
            slug: providerId ? getValue(providerQuery, 'data.provider.slug') : '',
            registrationNumber: providerId ? getValue(providerQuery, 'data.provider.registrationNumber') || '' : '',
            taxRegistrationNumber: providerId
              ? getValue(providerQuery, 'data.provider.taxRegistrationNumber') || ''
              : '',
            contactEmail: providerId ? getValue(providerQuery, 'data.provider.contactEmail') || '' : '',
            description: providerId ? getValue(providerQuery, 'data.provider.description') || '' : '',
            socialTwitter: providerId ? getValue(providerQuery, 'data.provider.socialTwitter') || '' : '',
            socialFacebook: providerId ? getValue(providerQuery, 'data.provider.socialFacebook') || '' : '',
            socialPinterest: providerId ? getValue(providerQuery, 'data.provider.socialPinterest') || '' : '',
            socialInstagram: providerId ? getValue(providerQuery, 'data.provider.socialInstagram') || '' : '',
            socialYoutube: providerId ? getValue(providerQuery, 'data.provider.socialYoutube') || '' : '',
            membershipFrom: providerId ? getValue(providerQuery, 'data.provider.membershipFrom') || '' : '',
            membershipTo: providerId ? getValue(providerQuery, 'data.provider.membershipTo') || '' : '',
            priority: providerId ? getValue(providerQuery, 'data.provider.priority') || '' : '',
            published: providerId ? getValue(providerQuery, 'data.provider.published') : true,
            image: providerId ? getValue(providerQuery, 'data.provider.image') : '',
            imageCropPosition: providerId ? getValue(providerQuery, 'data.provider.imageCropPosition') : 'TOP',
            cashback: providerId ? getValue(providerQuery, 'data.provider.cashback') || '' : '',
            salesman: providerId ? getValue(providerQuery, 'data.provider.salesman.id') : '',
            category: providerId ? getValue(providerQuery, 'data.provider.category.id') : '',
            tags: providerId ? (providerQuery.data && providerQuery.data.provider.tags.map((tag) => tag.id)) || [] : [],
          }}
          isInitialValid={!!providerId}
          validationSchema={CreateAndEditProviderSchema}
          onSubmit={(values) => {
            if (!providerId) {
              executeCreateProviderMutation({
                variables: {
                  name: values.name,
                  slug: values.slug,
                  registrationNumber: values.registrationNumber !== '' ? values.registrationNumber : null,
                  taxRegistrationNumber: values.taxRegistrationNumber !== '' ? values.taxRegistrationNumber : null,
                  contactEmail: values.contactEmail !== '' ? values.contactEmail.trim() : null,
                  description: values.description !== '' ? values.description.trim() : null,
                  socialTwitter: values.socialTwitter !== '' ? values.socialTwitter : null,
                  socialFacebook: values.socialFacebook !== '' ? values.socialFacebook : null,
                  socialPinterest: values.socialPinterest !== '' ? values.socialPinterest : null,
                  socialInstagram: values.socialInstagram !== '' ? values.socialInstagram : null,
                  socialYoutube: values.socialYoutube !== '' ? values.socialYoutube : null,
                  membershipFrom: values.membershipFrom !== '' ? values.membershipFrom : null,
                  membershipTo: values.membershipTo !== '' ? values.membershipTo : null,
                  priority: values.priority && values.priority !== '' ? Number.parseInt(values.priority + '') : null,
                  cashback: values.cashback && values.cashback !== '' ? Number.parseInt(values.cashback + '') : null,
                  published: values.published,
                  category: values.category,
                  salesman: values.salesman,
                  tags: values.tags,
                  image: values.image,
                  imageCropPosition: values.imageCropPosition,
                },
                refetchQueries: [{ query: PROVIDERS_QUERY }],
              });
            } else {
              executeUpdateProviderMutation({
                variables: {
                  id: providerId,
                  name: values.name,
                  slug: values.slug,
                  registrationNumber: values.registrationNumber !== '' ? values.registrationNumber : null,
                  taxRegistrationNumber: values.taxRegistrationNumber !== '' ? values.taxRegistrationNumber : null,
                  contactEmail: values.contactEmail !== '' ? values.contactEmail.trim() : null,
                  description: values.description !== '' ? values.description.trim() : null,
                  socialTwitter: values.socialTwitter !== '' ? values.socialTwitter : null,
                  socialFacebook: values.socialFacebook !== '' ? values.socialFacebook : null,
                  socialPinterest: values.socialPinterest !== '' ? values.socialPinterest : null,
                  socialInstagram: values.socialInstagram !== '' ? values.socialInstagram : null,
                  socialYoutube: values.socialYoutube !== '' ? values.socialYoutube : null,
                  membershipFrom: values.membershipFrom !== '' ? values.membershipFrom : null,
                  membershipTo: values.membershipTo !== '' ? values.membershipTo : null,
                  priority: values.priority && values.priority !== '' ? Number.parseInt(values.priority + '') : null,
                  cashback: values.cashback && values.cashback !== '' ? Number.parseInt(values.cashback + '') : null,
                  published: values.published,
                  category: values.category,
                  salesman: values.salesman,
                  tags: values.tags,
                  image: values.image,
                  imageCropPosition: values.imageCropPosition,
                },
                refetchQueries: [{ query: PROVIDERS_QUERY }, { query: PROVIDER_QUERY, variables: { id: providerId } }],
              });
            }
          }}
        >
          {(props) => (
            <Form>
              {(updateProviderMutation.error || createProviderMutation.error) && (
                <GraphqlError
                  error={updateProviderMutation.error || createProviderMutation.error}
                  defaultMessage="Nepovedlo se dodavatele uložit."
                />
              )}
              <Flex>
                <TextField
                  formikProps={props}
                  label="Název"
                  name="name"
                  autoFocus={!providerId}
                  isRequired
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.setFieldTouched('name', true);
                    props.setFieldValue('name', e.target.value);
                    if (!providerId) {
                      props.setFieldTouched('slug', true);
                      props.setFieldValue('slug', slugify(e.target.value, { lower: true, remove: /[*+,~.()'"!:@]/g }));
                    }
                  }}
                />
                <TextField
                  formikProps={props}
                  label="Slug"
                  name="slug"
                  isRequired
                  elemAfterInput={
                    <Button
                      iconBefore={<Icon name="update-left-rotation" />}
                      onClick={() => {
                        props.setFieldTouched('slug', true);
                        props.setFieldValue(
                          'slug',
                          slugify(props.values.name, {
                            lower: true,
                            remove: /[*+,~.()'"!:@]/g,
                          }),
                        );
                      }}
                    >
                      Aktualizovat
                    </Button>
                  }
                />
                <TextField formikProps={props} label="IČ" name="registrationNumber" />
                <TextField formikProps={props} label="DIČ" name="taxRegistrationNumber" />
                <TextField formikProps={props} label="Kontaktní email" name="contactEmail" />
                <TextField
                  formikProps={props}
                  label="Priorita"
                  name="priority"
                  type="number"
                  placeholder="Vyšší číslo, vyšší pozice"
                />

                <Card title="Obchodní zástupce" isRequired>
                  <Select
                    options={
                      usersFetcher.data && usersFetcher.data.users
                        ? [
                            ...usersFetcher.data.users.map((user) => ({
                              label: user.firstName + ' ' + user.lastName,
                              value: user.id,
                            })),
                          ]
                        : [{}]
                    }
                    name="salesman"
                    onChange={(item: { value: string; label: string }) => {
                      props.setFieldValue('salesman', item.value);
                    }}
                    value={
                      usersFetcher.data &&
                      usersFetcher.data.users
                        .map((user) => ({ label: user.firstName + ' ' + user.lastName, value: user.id }))
                        .find((user) => user.value === props.values.salesman)
                    }
                    isSearchable={true}
                    placeholder="Vyberte autora"
                  />
                </Card>

                <Card title="Kategorie" isRequired>
                  <Select
                    options={
                      categoriesFetcher.data && categoriesFetcher.data.categories
                        ? [
                            ...categoriesFetcher.data.categories.map((category) => ({
                              label: category.name,
                              value: category.id,
                            })),
                          ]
                        : {}
                    }
                    name="category"
                    onChange={(item: { value: string; label: string }) => {
                      console.log({ item });
                      props.setFieldValue('category', item.value);
                      props.setFieldValue('tags', []);
                    }}
                    value={
                      categoriesFetcher.data && categoriesFetcher.data.categories
                        ? categoriesFetcher.data.categories
                            .map((category) => ({ value: category.id, label: category.name }))
                            .find((category) => category.value === props.values.category)
                        : ''
                    }
                    isSearchable={true}
                    placeholder="Vyberte kategorii"
                  />
                </Card>

                <TextField
                  formikProps={props}
                  label="Provize"
                  name="cashback"
                  type="number"
                  placeholder=""
                  elemAfterInput={<div style={{ marginRight: 10 }}>%</div>}
                />

                <Card title="Publikováno">
                  <Switch
                    checked={props.values.published}
                    onClick={() => {
                      props.setFieldValue('published', !props.values.published);
                      props.setFieldTouched('published', true);
                    }}
                    checkedChildren={null}
                    unCheckedChildren={null}
                  />
                </Card>

                <Card title="Začátek platnosti členství">
                  <DatePicker
                    value={props.values.membershipFrom}
                    onChange={(value) => props.setFieldValue('membershipFrom', moment(value).utc().toISOString())}
                    locale="cs_CZ"
                  />
                </Card>

                <Card title="Konec platnosti členství">
                  <DatePicker
                    value={props.values.membershipTo}
                    onChange={(value) => props.setFieldValue('membershipTo', moment(value).utc().toISOString())}
                    locale="cs_CZ"
                  />
                </Card>
              </Flex>

              <Textarea formikProps={props} label="Popisek" name="description" minimumRows={5} isRequired />

              <h2>Štítky</h2>
              {!props.values.category && (
                <div style={{ marginTop: 15 }}>
                  <SectionMessage>Vyberte nejprve kategorii.</SectionMessage>
                </div>
              )}
              <Flex columns={5}>
                {filtersFetcher.data &&
                  filtersFetcher.data.filters
                    .filter((filter) => {
                      const categories = filter.categories.map((category) => category.id);

                      return categories.includes(props.values.category);
                    })
                    .filter((filter) => filter.tags.length > 0)
                    .filter((filter) => filter.activeForProvider)
                    .map((filter) => (
                      <div key={filter.id}>
                        <Label label={filter.name} />
                        {filter.tags.map((tag) => (
                          <Checkbox
                            isChecked={props.values.tags.includes(tag.id)}
                            label={`${tag.name} (${tag.slug})`}
                            key={tag.id}
                            onChange={() => {
                              var index = props.values.tags.indexOf(tag.id);
                              if (index > -1) {
                                let tags = props.values.tags;
                                tags.splice(index, 1);
                                props.setFieldValue('tags', tags);
                              } else {
                                let tags = props.values.tags;
                                tags.push(tag.id);
                                props.setFieldValue('tags', tags);
                              }
                            }}
                          />
                        ))}
                      </div>
                    ))}
              </Flex>

              <h2>Sociální sítě</h2>
              <Flex>
                <TextField
                  formikProps={props}
                  label="Twitter"
                  name="socialTwitter"
                  placeholder="https://twitter.com/adresa-stranky"
                />
                <TextField
                  formikProps={props}
                  label="Facebook"
                  name="socialFacebook"
                  placeholder="https://facebook.com/adresa-stranky"
                />
                <TextField
                  formikProps={props}
                  label="Pinterest"
                  name="socialPinterest"
                  placeholder="https://pinterest.com/adresa-stranky"
                />
                <TextField
                  formikProps={props}
                  label="Instagram"
                  name="socialInstagram"
                  placeholder="https://instagram.com/adresa-stranky"
                />
                <TextField
                  formikProps={props}
                  label="YouTube"
                  name="socialYoutube"
                  placeholder="https://youtube.com/adresa-stranky"
                />
              </Flex>

              <h2>Obrázek</h2>

              <Card title="Zarovnání obrázku na výpisu" isRequired>
                <Select
                  options={imageCropPositionValues}
                  name="imageCropPosition"
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('imageCropPosition', item.value);
                  }}
                  value={imageCropPositionValues.find((category) => category.value === props.values.imageCropPosition)}
                  isSearchable={true}
                  placeholder="Vyberte zarovnání"
                />
              </Card>

              <Card title="Soubor s obrázkem" isRequired>
                <FileField
                  updateParentState={(url) => {
                    props.setFieldValue('image', url);
                  }}
                  defaultState={props.values.image}
                  photoName={props.values.name}
                />
              </Card>

              <div style={{ marginTop: 20 }}>
                <LoadingButton
                  type="submit"
                  isLoading={updateProviderMutation.loading}
                  isDisabled={!props.isValid || updateProviderMutation.loading}
                  appearance="primary"
                >
                  Uložit
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

const imageCropPositionValues = [
  { label: 'Nahoru', value: 'TOP' },
  { label: 'Na střed', value: 'CENTER' },
  { label: 'Dolů', value: 'BOTTOM' },
];

export default CreateProviderPage;
