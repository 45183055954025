import 'rc-switch/assets/index.css';
import AlgoliaPlaces from 'algolia-places-react';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@atlaskit/button';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import Flex from '../components/Flex';
import Page from '../components/Page';
import TextField from '../components/TextField';
import { PROVIDER_QUERY, STORE_QUERY } from '../graphql/graphqlQueries';
import { useCitiesQuery, useCreateStoreMutation, useStoreQuery, useUpdateStoreMutation } from '../graphql/types';

const CreateAndEditProductSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Zadejte alespoň dva znaky.').required('Toto pole je povinné.'),
  address: Yup.string().min(2, 'Zadejte alespoň dva znaky.').required('Toto pole je povinné.'),
  lat: Yup.number().positive('Pouze kladné celé číslo.').min(1, 'Zadejte platnou hodnotu.').required(),
  lng: Yup.number().positive('Pouze kladné celé číslo.').min(1, 'Zadejte platnou hodnotu.').required(),
  priority: Yup.number().integer('Pouze celé číslo.').positive('Pouze kladné celé číslo.').nullable(),
  cityId: Yup.string().required('Vyberte město.'),
  phone: Yup.string().matches(/^[+]?[()/0-9. -]{12,}$/, 'Zadejte telefonní číslo ve formátu +420 xxx xxx xxx.'),
  web: Yup.string().url(),
  email: Yup.string().email(),
  openingHours: Yup.string(),
  zipCode: Yup.string().matches(/^\d{5}$/, 'Zadejte PSČ ve formátu xxxxx. Povolena jsou pouze čísla bez mezery.'),
});

const CreateProductPage = () => {
  const navigate = useNavigate();
  const { storeId } = useParams<'storeId'>();
  const storeIdParam = storeId !== 'add' ? storeId : undefined;
  const { providerId } = useParams<'providerId'>();

  const storeQuery = useStoreQuery({ variables: { id: storeIdParam + '' }, skip: !storeIdParam });
  const citiesQuery = useCitiesQuery();
  const [executeCreateStoreMutation, createStoreMutation] = useCreateStoreMutation();
  const [executeUpdateStoreMutation, updateStoreMutation] = useUpdateStoreMutation();

  if (
    (storeIdParam && storeQuery.called && !storeQuery.data) ||
    citiesQuery.loading ||
    citiesQuery.error ||
    !citiesQuery.data
  ) {
    return (
      <Page isLoading={storeQuery.loading || citiesQuery.loading} isError={!!storeQuery.error || !!citiesQuery.error} />
    );
  }

  if (
    (createStoreMutation && createStoreMutation.data && getValue(createStoreMutation.data, 'createStore.id')) ||
    (updateStoreMutation && updateStoreMutation.data && getValue(updateStoreMutation.data, 'updateStore.id'))
  ) {
    navigate(`/providers/${providerId}`);
  }

  return (
    <Page title={!storeIdParam ? 'Přidat pobočku' : 'Upravit pobočku'}>
      <Formik
        initialValues={{
          name: storeIdParam ? getValue(storeQuery, 'data.store.name') : '',
          address: storeIdParam ? getValue(storeQuery, 'data.store.address') : '',
          lat: storeIdParam ? getValue(storeQuery, 'data.store.lat') : '',
          lng: storeIdParam ? getValue(storeQuery, 'data.store.lng') : '',
          phone: storeIdParam ? getValue(storeQuery, 'data.store.phone') || '' : '',
          web: storeIdParam ? getValue(storeQuery, 'data.store.web') || '' : '',
          email: storeIdParam ? getValue(storeQuery, 'data.store.email') || '' : '',
          openingHours: storeIdParam ? getValue(storeQuery, 'data.store.openingHours') || '' : '',
          priority: storeIdParam ? getValue(storeQuery, 'data.store.priority') || '' : '',
          zipCode: storeIdParam ? getValue(storeQuery, 'data.store.zipCode') || '' : '',
          cityId: storeIdParam ? getValue(storeQuery, 'data.store.city.id') : '',
        }}
        isInitialValid={!!storeIdParam}
        validationSchema={CreateAndEditProductSchema}
        onSubmit={(values) => {
          if (!storeIdParam) {
            executeCreateStoreMutation({
              variables: {
                name: values.name,
                address: values.address,
                lat: Number.parseFloat(values.lat),
                lng: Number.parseFloat(values.lng),
                phone: values.phone === '' ? null : values.phone,
                web: values.web === '' ? null : values.web,
                email: values.email === '' ? null : values.email,
                openingHours: values.openingHours === '' ? null : values.openingHours,
                priority: values.priority === '' ? null : values.priority,
                zipCode: values.zipCode === '' ? null : Number.parseInt(values.zipCode),
                cityId: values.cityId,
                providerId: providerId ?? '',
              },
              refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
            });
          } else {
            executeUpdateStoreMutation({
              variables: {
                name: values.name,
                address: values.address,
                lat: Number.parseFloat(values.lat),
                lng: Number.parseFloat(values.lng),
                phone: values.phone === '' ? null : values.phone,
                web: values.web === '' ? null : values.web,
                email: values.email === '' ? null : values.email,
                openingHours: values.openingHours === '' ? null : values.openingHours,
                priority: values.priority === '' ? null : values.priority,
                zipCode: values.zipCode === '' ? null : Number.parseInt(values.zipCode),
                cityId: values.cityId,
                id: storeId ?? '',
              },
              refetchQueries: [
                { query: PROVIDER_QUERY, variables: { id: providerId } },
                { query: STORE_QUERY, variables: { id: storeId } },
              ],
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {(createStoreMutation.error || updateStoreMutation.error) && (
              <SectionMessage appearance="error">Nepovedlo se video uložit.</SectionMessage>
            )}
            <Flex>
              <TextField formikProps={props} label="Název" name="name" autoFocus={!storeIdParam} isRequired />
              <Card title="Zadejte celou adresu pro předvyplnění údajů o adrese">
                <AlgoliaPlaces
                  onChange={(data: any) => {
                    props.setFieldValue('address', data.suggestion.name);
                    props.setFieldTouched('address', true);
                    props.setFieldValue('lat', data.suggestion.latlng.lat);
                    props.setFieldTouched('lat', true);
                    props.setFieldValue('lng', data.suggestion.latlng.lng);
                    props.setFieldTouched('lng', true);
                    props.setFieldValue('zipCode', data.suggestion.postcode.replace(' ', ''));
                    props.setFieldTouched('zipCode', true);
                  }}
                  placeholder=""
                />
              </Card>
              <TextField formikProps={props} label="Adresa" name="address" isRequired />
              <Card title="Město" isRequired>
                <Select
                  options={
                    citiesQuery.data && citiesQuery.data.cities
                      ? [
                          ...citiesQuery.data.cities.map((city) => ({
                            label: city.name,
                            value: city.id,
                          })),
                        ]
                      : {}
                  }
                  name="cityId"
                  value={
                    citiesQuery.data &&
                    citiesQuery.data.cities
                      .map((city) => ({ label: city.name, value: city.id }))
                      .find((city) => city.value === props.values.cityId)
                  }
                  isSearchable={true}
                  onChange={(item: { value: string; label: string }) => {
                    props.setFieldValue('cityId', item.value);
                  }}
                  placeholder="Vyberte město"
                />
              </Card>
              <TextField formikProps={props} label="Zeměpisná šířka" name="lat" isRequired />
              <TextField formikProps={props} label="Zeměpisná výška" name="lng" isRequired />
              <TextField formikProps={props} label="PSČ" name="zipCode" type="number" />
              <TextField formikProps={props} label="Telefon" name="phone" />
              <TextField formikProps={props} label="Webové stránky pobočky" name="web" />
              <TextField formikProps={props} label="Emailová adresa" name="email" />
              <TextField formikProps={props} label="Otevírací doba" name="openingHours" />
              <TextField formikProps={props} label="Priorita" name="priority" type="number" />
            </Flex>

            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createStoreMutation.loading || updateStoreMutation.loading}
                isDisabled={!props.isValid || createStoreMutation.loading || updateStoreMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateProductPage;
