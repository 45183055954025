import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Page from '../components/Page';
import { useNavigate } from 'react-router-dom';
import { useInspirationPhotosQuery } from '../graphql/types';
import Img from 'react-cloudimage-responsive';
import SearchInput, { searchData } from '../components/SearchInput';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button';
import Icon from '../components/Icon';

const PhotosPage = () => {
  const navigate = useNavigate();
  const [needle, setNeedle] = useState('');

  const { loading, data, error } = useInspirationPhotosQuery();

  if (loading || error || !data || !data.photos) {
    return <Page title="Svatební inspirace" isLoading={loading} isError={!!error} />;
  }

  const search = searchData(data.photos, ['name'], needle);

  return (
    <Page title="Svatební inspirace">
      <SearchInput
        value={needle}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNeedle(e.target.value)}
        onSubmit={() => navigate(`/posts/${search[0].id}`)}
        placeholder="Zadejte název fotky, kategorie nebo dodavatele"
      />
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'img', content: 'Fotka' },
            { key: 'nazev', content: 'Název' },
            { key: 'category', content: 'Kategorie' },
            { key: 'provider', content: 'Dodavatel' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          search &&
          search.map((photo) => ({
            cells: [
              {
                key: 'img',
                content: (
                  <div style={{ width: 75, height: 75 }}>
                    <Img
                      src={photo.link}
                      alt={photo.name}
                      params={{
                        func: 'crop',
                        w: 75,
                        h: 75,
                      }}
                      doNotReplaceURL={false}
                    />
                  </div>
                ),
              },
              { key: 'nazev', content: photo.name },
              { key: 'category', content: photo.categories.map((category: { name: string }) => category.name) },
              { key: 'provider', content: photo.provider.name },
              {
                key: 'premium',
                content: (
                  <ButtonGroup>
                    <Button
                      appearance="primary"
                      onClick={() => navigate(`/providers/${photo.provider.id}/photo/${photo.id}`)}
                      iconBefore={<Icon name="edit" color="#ffffff" />}
                    >
                      Editovat
                    </Button>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={search.length > 50 ? 50 : undefined}
        isLoading={loading}
        emptyView={<div>Žádné fotky.</div>}
      />
    </Page>
  );
};

export default PhotosPage;
