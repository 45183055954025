import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import FieldText from '@atlaskit/textfield';
import Card from './Card';
import FieldErrorMessage from './FieldErrorMessage';

interface Props {
  formikProps: FormikProps<FormikValues>;
  name: string;
  label: string;
  type?: 'text' | 'number';
  isRequired?: boolean;
  autoFocus?: boolean;
  elemAfterInput?: any;
  onChange?: any;
  placeholder?: string;
  isDisabled?: boolean;
}

const TextField = ({
  formikProps,
  name,
  label,
  type = 'text',
  isRequired,
  placeholder,
  isDisabled,
  ...restProps
}: Props) => (
  <Card title={label} isRequired={isRequired}>
    <FieldText
      type={type}
      onChange={formikProps.handleChange}
      onBlur={formikProps.handleBlur}
      value={formikProps.values[name]}
      isInvalid={!!(formikProps.errors[name] && formikProps.touched[name])}
      name={name}
      isRequired={isRequired}
      placeholder={placeholder}
      isDisabled={isDisabled}
      {...restProps}
    />
    {formikProps.errors[name] && formikProps.touched[name] ? (
      <FieldErrorMessage>{formikProps.errors[name]}</FieldErrorMessage>
    ) : null}
  </Card>
);

export default TextField;
