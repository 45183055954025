import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import getBaseUrl from 'get-base-url';
import cookies from 'js-cookie';
import fetch from 'node-fetch';

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  fetch,
});

// @ts-ignore
const authLink = setContext((_, { headers }) => {
  var token = cookies.get('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      serverurl: getBaseUrl(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
