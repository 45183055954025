import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import React from 'react';
import Img from 'react-cloudimage-responsive';
import _ReactPlayer, { ReactPlayerProps } from 'react-player';
import { useNavigate, useParams } from 'react-router-dom';
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button';
import DynamicTable from '@atlaskit/dynamic-table';
import Lozenge from '@atlaskit/lozenge';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import Badge from '@atlaskit/badge';
import Card from '../components/Card';
import Flex from '../components/Flex';
import Heading from '../components/Heading';
import Icon from '../components/Icon';
import { CroppedImage } from '../components/Image';
import Page from '../components/Page';
import { PROVIDER_QUERY, PROVIDERS_QUERY } from '../graphql/graphqlQueries';
import {
  DeletePhotoComponent,
  DeleteProductComponent,
  DeleteProviderComponent,
  DeleteRatingComponent,
  DeleteServiceComponent,
  DeleteStoreComponent,
  DeleteVideoComponent,
  RemoveUserFromProviderComponent,
  useProviderAnalyticsLazyQuery,
  useProviderQuery,
} from '../graphql/types';
import styled from 'styled-components';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

const ProviderPage = () => {
  const navigate = useNavigate();
  const { providerId } = useParams<'providerId'>();
  const { loading, data, error } = useProviderQuery({ variables: { id: providerId ?? '' } });

  const [callAnalyticsQuery, analyticsFetcher] = useProviderAnalyticsLazyQuery();

  if (loading || error || !data || !data.provider) {
    return <Page isLoading={loading} isError={!!error} />;
  }

  if (data.provider && data.provider.slug && !analyticsFetcher.called) {
    callAnalyticsQuery({ variables: { providerSlug: data.provider.slug } });
  }

  const analytics = analyticsFetcher.data && analyticsFetcher.data.providerAnalytics;

  const analyticsKeysToTitles = {
    clicked_gps: 'Kliknutí na GPS',
    dial_email: 'Volání emailu',
    go_to_about: 'Přejít na O nás',
    go_to_contacts: 'Přejít na Kontakty',
    go_to_facebook: 'Přejít na Facebook',
    go_to_gallery: 'Přejít do Galerie',
    go_to_instagram: 'Přejít na Instagram',
    go_to_products: 'Přejít na Produkty',
    go_to_ratings: 'Přejít na Hodnocení',
    go_to_services: 'Přejít na Služby',
    go_to_twitter: 'Přejít na Twitter',
    go_to_videos: 'Přejít na Videa',
    go_to_web: 'Přejít na Web',
    go_to_youtube: 'Přejít na YouTube',
    hide_message_form: 'Skrýt formulář zprávy',
    play_video: 'Přehrát video',
    send_email: 'Odeslat email',
    show_email: 'Zobrazit email',
    show_less_product_info: 'Zobrazit méně informací o produktu',
    show_message_form: 'Zobrazit formulář zprávy',
    show_message_form_sidebar: 'Zobrazit postranní panel formuláře zprávy',
    show_more_contacts: 'Zobrazit více kontaktů',
    show_more_product_info: 'Zobrazit více informací o produktu',
    show_phone: 'Zobrazit telefon',
    show_photo: 'Zobrazit foto',
    show_web: 'Zobrazit web',
    visit: 'Návštěvy',
  };

  const generateStatBoxes = (analytics: any) => {
    // Speciální zpracování pro 'visit'
    const statBoxes: React.ReactNode[] = [];
    if (analytics.visit) {
      statBoxes.push(
        <StatBox key="visits" number={Math.round(analytics.visit)} title={analyticsKeysToTitles.visit || 'Visit'} />,
      );
    }

    // Generování pro ostatní klíče
    Object.entries(analytics).forEach(([key, value]) => {
      if ((value as number) > 0 && key !== 'visit') {
        // Přeskočit 'visit', protože už byl zpracován
        //@ts-ignore
        const title = analyticsKeysToTitles[key] || key; // Použijte mapování nebo klíč jako náhradní titul
        statBoxes.push(<StatBox key={key} number={Math.round(value as number)} title={title} />);
      }
    });

    return statBoxes;
  };

  return (
    <Page title={data.provider.name}>
      <ToastContainer enableMultiContainer position={toast.POSITION.TOP_RIGHT} />
      <Flex>
        <Heading>Profil</Heading>
        <LoadingButton
          iconBefore={<Icon name="edit" />}
          isLoading={loading}
          onClick={() => navigate(`/providers/${data.provider.id}/edit`)}
        >
          Upravit profil
        </LoadingButton>
      </Flex>

      <Flex style={{ marginBottom: 40 }}>
        <Card title="IČ">{data.provider.registrationNumber || <Lozenge>Není zadáno</Lozenge>}</Card>
        <Card title="DIČ">{data.provider.taxRegistrationNumber || <Lozenge>Neplátce DPH</Lozenge>}</Card>
        <Card title="Staré ID z CRM">{data.provider.oldId || <Lozenge>Založeno v novém systému</Lozenge>}</Card>
        <Card title="Provize">
          {data.provider.cashback ? data.provider.cashback + ' %' : <Lozenge>Není provize</Lozenge>}
        </Card>
        <Card title="Kategorie">{data.provider.category.name}</Card>
        <Card title="Štítky">
          {data.provider.tags.map((tag) => (
            <div style={{ display: 'inline-block', marginRight: 5 }}>
              <Lozenge key={tag.id} appearance="new">
                {tag.name}
              </Lozenge>
            </div>
          )) || <Lozenge>Žádné štítky</Lozenge>}
        </Card>
        <Card title="Kontaktní email">{data.provider.contactEmail || <Lozenge>Není kontaktní email</Lozenge>}</Card>
        <Card title="Sociální sítě">
          {!data.provider.socialTwitter &&
            !data.provider.socialFacebook &&
            !data.provider.socialPinterest &&
            !data.provider.socialInstagram &&
            !data.provider.socialYoutube && <Lozenge>Žádné sociální sítě</Lozenge>}

          {data.provider.socialTwitter && (
            <a
              href={data.provider.socialTwitter}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10, marginTop: 5, display: 'inline-block' }}
            >
              <Icon name="twitter" />
            </a>
          )}

          {data.provider.socialFacebook && (
            <a
              href={data.provider.socialFacebook}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10, marginTop: 5, display: 'inline-block' }}
            >
              <Icon name="facebook" />
            </a>
          )}

          {data.provider.socialPinterest && (
            <a
              href={data.provider.socialPinterest}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10, marginTop: 5, display: 'inline-block' }}
            >
              <Icon name="pinterest" />
            </a>
          )}

          {data.provider.socialInstagram && (
            <a
              href={data.provider.socialInstagram}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10, marginTop: 5, display: 'inline-block' }}
            >
              <Icon name="instagram" />
            </a>
          )}

          {data.provider.socialYoutube && (
            <a
              href={data.provider.socialYoutube}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 10, marginTop: 5, display: 'inline-block' }}
            >
              <Icon name="youtube" />
            </a>
          )}
        </Card>

        <Card title="Začátek platnosti členství">
          {data.provider.membershipFrom && (
            <div>{moment(data.provider.membershipFrom).format('DD. MM. YYYY').toString()} </div>
          )}
          {!data.provider.membershipFrom && <Lozenge>Nezadáno</Lozenge>}
        </Card>
        <Card title="Konec platnosti členství">
          {data.provider.membershipTo && (
            <div>{moment(data.provider.membershipTo).format('DD. MM. YYYY').toString()} </div>
          )}
          {!data.provider.membershipTo && <Lozenge>Nezadáno</Lozenge>}
        </Card>
        <Card title="Priorita">{data.provider.priority || <Lozenge>Výchozí</Lozenge>}</Card>
        <Card title="Publikováno">
          <Lozenge appearance={data.provider.published ? 'success' : 'removed'}>
            {data.provider.published ? 'Ano' : 'Ne'}
          </Lozenge>
        </Card>
        <Card title="Slug v url">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.provider.slug}&nbsp;
            <a href={`https://svatba.cz/dodavatel/${data.provider.slug}`} target="_blank" rel="noopener noreferrer">
              <Icon name="globe" />
            </a>
          </div>
        </Card>
        {data.provider.salesman && (
          <Card title="Obchodní zástupce">
            {data.provider.salesman.firstName} {data.provider.salesman.lastName}
          </Card>
        )}
        <Card title="Profil vytvořen dne">
          {moment(data.provider.createdAt).format('DD. MM. YYYY HH:mm').toString()}
        </Card>
        <Card title="Profil aktualizován dne">
          {moment(data.provider.updatedAt).format('DD. MM. YYYY HH:mm').toString()}
        </Card>
      </Flex>

      <Card title="Popis">{data.provider.description || <Lozenge>Bez popisku</Lozenge>}</Card>
      <Flex>
        <Card title="Obrázek">
          <Img src={data.provider.image} alt="" size="300" doNotReplaceURL={false} />
        </Card>
        <Card title="Zarovnání obrázku (vertikálně na feedu)">
          <CroppedImage
            src={data.provider.image}
            width={400}
            height={400}
            position={data.provider.imageCropPosition as unknown as 'TOP' | 'BOTTOM' | 'CENTER'}
          />
        </Card>
      </Flex>

      <Flex style={{ marginTop: 30 }}>
        <Heading>Fotografie</Heading>
        <LoadingButton
          iconBefore={<Icon name="plus" />}
          isLoading={loading}
          onClick={() => navigate(`/providers/${data.provider.id}/photo/add`)}
        >
          Přidat fotografii
        </LoadingButton>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'preview', content: 'Fotografie', width: '75px' },
            { key: 'name', content: 'Název' },
            { key: 'showInInspiration', content: 'Inspirace' },
            { key: 'showInGallery', content: 'Galerie' },
            { key: 'priorityInspiration', content: 'Priorita v inspiraci' },
            { key: 'priorityGallery', content: 'Priorita v galerii' },
            { key: 'category', content: 'Kategorie' },
            { key: 'tags', content: 'Štítky' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          data &&
          data.provider.photos &&
          data.provider.photos.map((photo) => ({
            cells: [
              {
                key: 'preview',
                content: (
                  <Img
                    src={photo.link}
                    alt={photo.name}
                    params={{
                      func: 'crop',
                      w: 75,
                      h: 75,
                    }}
                    doNotReplaceURL={false}
                  />
                ),
              },
              { key: 'name', content: photo.name },
              {
                key: 'showInInspiration',
                content: (
                  <Lozenge appearance={photo.showInInspiration ? 'success' : 'default'}>
                    {photo.showInInspiration ? 'Ano' : 'Ne'}
                  </Lozenge>
                ),
              },
              {
                key: 'showInGallery',
                content: (
                  <Lozenge appearance={photo.showInGallery ? 'success' : 'default'}>
                    {photo.showInGallery ? 'Ano' : 'Ne'}
                  </Lozenge>
                ),
              },
              { key: 'priority', content: photo.priorityInspiration },
              { key: 'priority', content: photo.priorityGallery },
              { key: 'category', content: photo.categories.map((category) => category.name).join(', ') },
              { key: 'tags', content: photo.tags.map((tag) => tag.name).join(', ') },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      iconBefore={<Icon name="edit" />}
                      onClick={() => navigate(`/providers/${data.provider.id}/photo/${photo.id}`)}
                    />
                    <DeletePhotoComponent refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}>
                      {(deletePhoto, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete smazat tuto položku?'))
                              deletePhoto({
                                variables: { id: photo.id },
                              });
                          }}
                        />
                      )}
                    </DeletePhotoComponent>
                    <Dropdown>
                      <Hover>
                        <Icon name="pinterest" />
                      </Hover>
                      <DropdownContent>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/o76yb0n', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - inspirace.');
                          }}
                        >
                          Inspirace
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj3v847', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - svatební cesta.');
                          }}
                        >
                          Svatební cesta
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2o0f1', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - místo.');
                          }}
                        >
                          Místo na svatbu
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2optl', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - kytice.');
                          }}
                        >
                          Kytice a dekorace
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2on7u', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - šaty.');
                          }}
                        >
                          Svatební šaty
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2byle', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - fotografie.');
                          }}
                        >
                          Svatební fotografie
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2bbij', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - oznameni.');
                          }}
                        >
                          Svatební oznámení
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2b2ny', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - prsteny.');
                          }}
                        >
                          Svatební prsteny
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2b4va', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - líčení.');
                          }}
                        >
                          Svatební líčení
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2bhh1', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - dort.');
                          }}
                        >
                          Svatební dort
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/oj2bmi1', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - zábava.');
                          }}
                        >
                          Zábava na svatbu
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/ojfnpva', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - oblek.');
                          }}
                        >
                          Svatební oblek
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/ojf72ls', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - boty.');
                          }}
                        >
                          Svatební boty a doplňky
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/ojf7hus', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - doprava.');
                          }}
                        >
                          Doprava na svatbu
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            fetch('https://hooks.zapier.com/hooks/catch/9645726/ojf79v8', {
                              method: 'POST',
                              body: JSON.stringify({
                                photo: photo.link,
                                photoTitle: photo.name,
                                provider: data.provider.name,
                                providerSlug: data.provider.slug,
                                category: photo.categories[0].name,
                              }),
                            });
                            toast.info('Fotka odeslána na pinterest - dary.');
                          }}
                        >
                          Svatební dary
                        </DropdownItem>
                      </DropdownContent>
                    </Dropdown>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádné fotografie.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>Produkty</Heading>
        <LoadingButton
          iconBefore={<Icon name="plus" />}
          isLoading={loading}
          onClick={() => navigate(`/providers/${data.provider.id}/product/add`)}
        >
          Přidat produkt
        </LoadingButton>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'preview', content: 'Fotografie', width: '75px' },
            { key: 'name', content: 'Název' },
            { key: 'price', content: 'Cena' },
            { key: 'priority', content: 'Priorita' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          data &&
          data.provider.products &&
          data.provider.products.map((product) => ({
            cells: [
              {
                key: 'preview',
                content: product.photo && (
                  <Img
                    src={product.photo.link}
                    alt={product.photo.name}
                    params={{
                      func: 'crop',
                      w: 75,
                      h: 75,
                    }}
                    doNotReplaceURL={false}
                  />
                ),
              },
              { key: 'name', content: product.name },
              { key: 'price', content: product.price ? product.price + ' Kč' : null },
              { key: 'priority', content: product.priority },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      iconBefore={<Icon name="edit" />}
                      onClick={() => navigate(`/providers/${data.provider.id}/product/${product.id}`)}
                    />
                    <DeleteProductComponent refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}>
                      {(deleteProvider, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete smazat tuto položku?'))
                              deleteProvider({
                                variables: { id: product.id },
                              });
                          }}
                        />
                      )}
                    </DeleteProductComponent>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádné produkty.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>Služby</Heading>
        <LoadingButton
          iconBefore={<Icon name="plus" />}
          isLoading={loading}
          onClick={() => navigate(`/providers/${data.provider.id}/service/add`)}
        >
          Přidat službu
        </LoadingButton>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'name', content: 'Název' },
            { key: 'priority', content: 'Priorita' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          data &&
          data.provider.services &&
          data.provider.services.map((service) => ({
            cells: [
              { key: 'name', content: service.name },
              { key: 'priority', content: service.priority },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      iconBefore={<Icon name="edit" />}
                      onClick={() => navigate(`/providers/${data.provider.id}/service/${service.id}`)}
                    />
                    <DeleteServiceComponent refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}>
                      {(deleteProvider, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete smazat tuto položku?'))
                              deleteProvider({
                                variables: { id: service.id },
                              });
                          }}
                        />
                      )}
                    </DeleteServiceComponent>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádné služby.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>Video</Heading>
        <LoadingButton
          iconBefore={<Icon name="plus" />}
          isLoading={loading}
          onClick={() => navigate(`/providers/${data.provider.id}/video/add`)}
        >
          Přidat video
        </LoadingButton>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'preview', content: 'Náhled' },
            { key: 'priority', content: 'Priorita' },
            { key: 'actions', content: 'Akce', width: '80px' },
          ],
        }}
        rows={
          data &&
          data.provider.videos &&
          data.provider.videos.map((video) => ({
            cells: [
              { key: 'preview', content: <ReactPlayer url={video.link} controls /> },
              { key: 'priority', content: video.priority },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      iconBefore={<Icon name="edit" />}
                      onClick={() => navigate(`/providers/${data.provider.id}/video/${video.id}`)}
                    />
                    <DeleteVideoComponent refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}>
                      {(deleteVideo, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete smazat tuto položku?'))
                              deleteVideo({
                                variables: { id: video.id },
                              });
                          }}
                        />
                      )}
                    </DeleteVideoComponent>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádná videa.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>Pobočky</Heading>
        <LoadingButton
          iconBefore={<Icon name="plus" />}
          isLoading={loading}
          onClick={() => navigate(`/providers/${data.provider.id}/store/add`)}
        >
          Přidat pobočku
        </LoadingButton>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'name', content: 'Název' },
            { key: 'address', content: 'Adresa' },
            { key: 'priority', content: 'Priorita' },
            { key: 'actions', content: 'Akce', width: '80px' },
          ],
        }}
        rows={
          data &&
          data.provider.stores &&
          data.provider.stores.map((store) => ({
            cells: [
              { key: 'name', content: store.name },
              { key: 'address', content: `${store.address}, ${store.city.name}` },
              { key: 'priority', content: store.priority },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      iconBefore={<Icon name="edit" />}
                      onClick={() => navigate(`/providers/${data.provider.id}/store/${store.id}`)}
                    />
                    <DeleteStoreComponent refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}>
                      {(deleteVideo, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete smazat tuto položku?'))
                              deleteVideo({
                                variables: { id: store.id },
                              });
                          }}
                        />
                      )}
                    </DeleteStoreComponent>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádné pobočky.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>Hodnocení</Heading>
        <Button iconBefore={<Icon name="plus" />} onClick={() => navigate(`/providers/${data.provider.id}/rating/add`)}>
          Přidat hodnocení
        </Button>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'name', content: 'Jméno hodnotícího' },
            { key: 'numberOfStars', content: 'Počet hvězdiček' },
            { key: 'content', content: 'Text recenze' },
            { key: 'feedback', content: 'Odpověď' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          data &&
          data.provider.ratings &&
          data.provider.ratings.map((rating) => ({
            cells: [
              {
                key: 'name',
                content:
                  rating.userName || `${rating.user && rating.user.firstName} ${rating.user && rating.user.lastName}`,
              },
              {
                key: 'numberOfStars',
                content: Array.from(Array(rating.numberOfStars).keys()).map((n) => <span>★</span>),
              },
              { key: 'content', content: rating.content },
              {
                key: 'feedback',
                content: (
                  <Lozenge appearance={rating.feedbackAt ? 'success' : 'default'}>
                    {rating.feedbackAt ? moment(rating.feedbackAt).format('DD. MM. YYYY').toString() : 'Bez vyjádření'}
                  </Lozenge>
                ),
              },
              {
                key: 'actions',
                content: (
                  <ButtonGroup>
                    <Button
                      iconBefore={<Icon name="edit" />}
                      onClick={() => navigate(`/providers/${data.provider.id}/rating/${rating.id}`)}
                    />
                    <DeleteRatingComponent refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}>
                      {(removeRating, { loading }) => (
                        <LoadingButton
                          appearance="danger"
                          iconBefore={<Icon name="trash" color="#ffffff" />}
                          isLoading={loading}
                          onClick={() => {
                            if (window.confirm('Chcete odebrat toto hodnocení?'))
                              removeRating({
                                variables: { id: rating.id },
                              });
                          }}
                        />
                      )}
                    </DeleteRatingComponent>
                  </ButtonGroup>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádná hodnocení.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>V plánovači</Heading>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [{ key: 'name', content: 'Jméno snoubence' }],
        }}
        rows={
          data &&
          data.provider.plannerUsers &&
          data.provider.plannerUsers.map((plannerUser) => ({
            cells: [
              {
                key: 'name',
                content: `${plannerUser.firstName} ${plannerUser.lastName}`,
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Nikdo si dodavatele do plánovače nepřidal.</div>}
      />

      <Flex style={{ marginTop: 50 }}>
        <Heading>Správci dodavatele</Heading>
        <Button iconBefore={<Icon name="plus" />} onClick={() => navigate(`/providers/${data.provider.id}/user/add`)}>
          Přidat uživatele
        </Button>
      </Flex>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'name', content: 'Jméno' },
            { key: 'email', content: 'Email' },
            { key: 'actions', content: 'Akce', width: '40px' },
          ],
        }}
        rows={
          data &&
          data.provider.users &&
          data.provider.users.map((user) => ({
            cells: [
              { key: 'name', content: user.firstName + ' ' + user.lastName },
              { key: 'email', content: user.email },
              {
                key: 'actions',
                content: (
                  <RemoveUserFromProviderComponent
                    refetchQueries={[{ query: PROVIDER_QUERY, variables: { id: providerId } }]}
                  >
                    {(removeUser, { loading }) => (
                      <LoadingButton
                        appearance="danger"
                        iconBefore={<Icon name="trash" color="#ffffff" />}
                        isLoading={loading}
                        onClick={() => {
                          if (window.confirm('Chcete odebrat tohoto uživatele?'))
                            removeUser({
                              variables: { provider: providerId ?? '', user: user.id },
                            });
                        }}
                      />
                    )}
                  </RemoveUserFromProviderComponent>
                ),
              },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        isLoading={loading}
        emptyView={<div>Žádní správci.</div>}
      />

      <Flex>
        <Heading>Statistiky</Heading>
      </Flex>
      <Flex columns={3}>
        {analytics && generateStatBoxes(analytics)}
        <StatBox number={data.provider.userLikesCount} title={'Přidání do oblíbených'} />
      </Flex>

      <Flex style={{ marginTop: 50 }}>
        <Heading>Nebezpečná zóna</Heading>
      </Flex>
      <SectionMessage appearance="error" title="Smazání dodavatelé">
        <div style={{ marginBottom: 15 }}>
          <p>
            V této sekci máte možnost smazat dodavatele. Smazáním dodavatele se dodavatel odstraní z katalogu, nebude
            dostupný ani při přímém zadání URL adresy dodavatele na portále. Pokud jste smazali dodavatele omylem,
            kontaktujte technickou podporu a ta data dodavatele obnoví.
          </p>
        </div>
        <DeleteProviderComponent refetchQueries={[{ query: PROVIDERS_QUERY }]}>
          {(deleteProviderMutation, { data, loading, error, called }) => {
            if (error) {
              return <div style={{ color: '#bf2700' }}>Nepovedlo se smazat dodavatele.</div>;
            }

            if (loading) {
              return <Spinner />;
            }

            if (data) {
              navigate('/');
            }

            if (!called) {
              return (
                <Button
                  appearance="danger"
                  onClick={() => {
                    if (window.confirm('Chcete smazat tuto položku?'))
                      deleteProviderMutation({ variables: { id: providerId ?? '' } });
                  }}
                >
                  Smazat dodavatele
                </Button>
              );
            }

            return null;
          }}
        </DeleteProviderComponent>
      </SectionMessage>
    </Page>
  );
};

export default ProviderPage;

const StatBox = ({ number, title }: { number: number; title: string | null }) => {
  if (title === null) return null;

  return (
    <div>
      {title} -&gt; <Badge max={1000000}>{number}</Badge>
    </div>
  );
};

const Hover = styled.div`
  color: white;
  padding: 5px;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropdownItem = styled.div`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover {
    ${DropdownContent} {
      display: block;
    }
  }
`;
