import React, { useState } from 'react';
import styled from 'styled-components';

const cloudimgBaseUrl = 'https://athhnphopm.cloudimg.io';

export const Image = (props: { src: string }) => {
  const [loadedHighResImage, setLoadedHighResImage] = useState(false);

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={`${cloudimgBaseUrl}/width/25/n/${props.src}`}
        style={{
          width: '100%',
          height: 'auto',
          opacity: loadedHighResImage ? 0 : 1,
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 1,
          lineHeight: 0,
        }}
        alt=""
      />

      <img
        src={`${cloudimgBaseUrl}/width/1000/n/${props.src}`}
        style={{
          width: '100%',
          height: 'auto',
          lineHeight: 0,
        }}
        onLoad={() => setTimeout(() => setLoadedHighResImage(true), 100)}
        alt=""
      />
    </div>
  );
};

export const CroppedImage = (props: {
  width: number;
  height: number;
  src: string;
  position: 'TOP' | 'BOTTOM' | 'CENTER';
}) => {
  // eslint-disable-next-line
  const Wrapper = styled.div<{
    width: number;
    height: number;
  }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    overflow: hidden;
    position: relative;
  `;

  // eslint-disable-next-line
  const Img = styled.img<{
    width: number;
    height: number;
    position: 'TOP' | 'BOTTOM' | 'CENTER';
  }>`
    line-height: 1;
    width: 100%;
    height: ${({ height }) => height}px;
    object-fit: cover;
    object-position: ${({ position }) => position.toLocaleLowerCase()} center;
  `;

  return (
    <Wrapper {...props}>
      <Img
        src={`${cloudimgBaseUrl}/width/${props.width * 2}/x/${props.src}`}
        width={props.width}
        height={props.height}
        position={props.position}
      />
    </Wrapper>
  );
};

export default Image;
