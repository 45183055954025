import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import TextArea from '@atlaskit/textarea';
import Card from './Card';
import FieldErrorMessage from './FieldErrorMessage';

interface Props {
  formikProps: FormikProps<FormikValues>;
  name: string;
  label: string;
  isRequired?: boolean;
  minimumRows?: number;
}

const Textarea = ({ formikProps, name, label, isRequired, ...restProps }: Props) => (
  <Card title={label} isRequired={isRequired}>
    <TextArea
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        formikProps.setFieldValue(name, e.target.value);
        formikProps.setFieldTouched(name, true);
      }}
      onBlur={formikProps.handleBlur}
      value={formikProps.values[name]}
      isInvalid={!!(formikProps.touched[name] && formikProps.errors[name])}
      {...restProps}
    />
    {formikProps.errors[name] && formikProps.touched[name] ? (
      <FieldErrorMessage>{formikProps.errors[name]}</FieldErrorMessage>
    ) : null}
  </Card>
);

export default Textarea;
