import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
import * as ApolloComponents from '@apollo/client/react/components';
import * as React from 'react';

export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO Date */
  DateTime: any;
  /** desc */
  Upload: any;
};
export type Analytics2 = {
  __typename?: 'Analytics2';
  clicked_gps?: Maybe<Scalars['Int']>;
  dial_email?: Maybe<Scalars['Int']>;
  go_to_about?: Maybe<Scalars['Int']>;
  go_to_contacts?: Maybe<Scalars['Int']>;
  go_to_facebook?: Maybe<Scalars['Int']>;
  go_to_gallery?: Maybe<Scalars['Int']>;
  go_to_instagram?: Maybe<Scalars['Int']>;
  go_to_products?: Maybe<Scalars['Int']>;
  go_to_ratings?: Maybe<Scalars['Int']>;
  go_to_services?: Maybe<Scalars['Int']>;
  go_to_twitter?: Maybe<Scalars['Int']>;
  go_to_videos?: Maybe<Scalars['Int']>;
  go_to_web?: Maybe<Scalars['Int']>;
  go_to_youtube?: Maybe<Scalars['Int']>;
  hide_message_form?: Maybe<Scalars['Int']>;
  play_video?: Maybe<Scalars['Int']>;
  send_email?: Maybe<Scalars['Int']>;
  show_email?: Maybe<Scalars['Int']>;
  show_less_product_info?: Maybe<Scalars['Int']>;
  show_message_form?: Maybe<Scalars['Int']>;
  show_message_form_sidebar?: Maybe<Scalars['Int']>;
  show_more_contacts?: Maybe<Scalars['Int']>;
  show_more_product_info?: Maybe<Scalars['Int']>;
  show_phone?: Maybe<Scalars['Int']>;
  show_photo?: Maybe<Scalars['Int']>;
  show_web?: Maybe<Scalars['Int']>;
  visit?: Maybe<Scalars['Int']>;
};
export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  image: Scalars['String'];
  icon: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  filters: Array<Filter>;
  providers: Array<Provider>;
  posts: Array<Post>;
  photos: Array<Photo>;
  groups: Array<Group>;
  discussionThreads: Array<DiscussionThread>;
  discussionThreadsCount: Scalars['Int'];
  providerSeoDescription: Scalars['String'];
  providerSeoShortText: Scalars['String'];
  providerSeoKeywords: Array<Scalars['String']>;
  postSeoDescription: Scalars['String'];
  postSeoShortText: Scalars['String'];
  postSeoKeywords: Array<Scalars['String']>;
  photoSeoDescription: Scalars['String'];
  photoSeoShortText: Scalars['String'];
  photoSeoKeywords: Array<Scalars['String']>;
  discussionSeoDescription: Scalars['String'];
  priceStart?: Maybe<Scalars['Int']>;
  priceStandard?: Maybe<Scalars['Int']>;
  priceExtra?: Maybe<Scalars['Int']>;
  pricePremium?: Maybe<Scalars['Int']>;
};
export type City = {
  __typename?: 'City';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameOfficial: Scalars['String'];
  locative: Scalars['String'];
  slug: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  radius: Scalars['Float'];
  stores: Array<Store>;
  region: Region;
};
export type DiscussionMessage = {
  __typename?: 'DiscussionMessage';
  id: Scalars['ID'];
  content: Scalars['String'];
  user: DiscussionUser;
  thread: DiscussionThread;
  quotedMessage?: Maybe<DiscussionMessage>;
  images?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type DiscussionThread = {
  __typename?: 'DiscussionThread';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  category: Category;
  messages: Array<DiscussionMessage>;
  lastMessage?: Maybe<DiscussionMessage>;
  messagesCount: Scalars['Int'];
  user: DiscussionUser;
  createdAt: Scalars['DateTime'];
};
export type DiscussionUser = {
  __typename?: 'DiscussionUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  wedding?: Maybe<Wedding>;
  providers: Array<Provider>;
};

export type FapiOrder = {
  __typename?: 'FapiOrder';
  id: Scalars['Int'];
  invoice?: Maybe<Scalars['Int']>;
  next_url?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  items: Array<FapiOrderItem>;
};

export type FapiOrderItem = {
  __typename?: 'FapiOrderItem';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  vat?: Maybe<Scalars['Int']>;
};
export type Filter = {
  __typename?: 'Filter';
  id: Scalars['ID'];
  name: Scalars['String'];
  activeForProvider: Scalars['Boolean'];
  activeForInspiration: Scalars['Boolean'];
  priority?: Maybe<Scalars['Int']>;
  tags: Array<Tag>;
  categories: Array<Category>;
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  image: Scalars['String'];
  icon: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  categories: Array<Category>;
};

export type Guest = {
  __typename?: 'Guest';
  id: Scalars['ID'];
  name: Scalars['String'];
  surname: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  type: GuestType;
};

export enum GuestType {}

export enum HorizontalPosition {}
export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  content: Scalars['String'];
  sender: User;
  thread: Thread;
  createdAt: Scalars['DateTime'];
};

export type Mutation = {
  __typename?: 'Mutation';
  uploadFile: Scalars['String'];
  createProvider: Provider;
  deleteProvider: Provider;
  likeProvider: Provider;
  updateProvider: Provider;
  createPost: Post;
  deletePost: Post;
  updatePost: Post;
  createPhoto: Photo;
  deletePhoto: Photo;
  likePhoto: Photo;
  updatePhoto: Photo;
  createProduct: Product;
  deleteProduct: Product;
  updateProduct: Product;
  createService: Service;
  deleteService: Service;
  updateService: Service;
  createMessage: Thread;
  updateMessagesSeenStatus: Scalars['Boolean'];
  updateThreadOffer: Thread;
  addUserToProvider: Provider;
  createUser: User;
  generateUsersCsv: Scalars['String'];
  login: Scalars['Boolean'];
  registration: Scalars['Boolean'];
  removeUserFromProvider: Provider;
  updateUser: User;
  adminCreateRating: Rating;
  createRating: Rating;
  createRatingFeedback: Rating;
  deleteRating: Rating;
  updateRating: Rating;
  createVideo: Video;
  deleteVideo: Video;
  updateVideo: Video;
  createStore: Store;
  deleteStore: Store;
  updateStore: Store;
  sendSupportMessage: Scalars['Boolean'];
  createPlanner: Planner;
  updatePlanner: Planner;
  addProviderToTodo: Scalars['Boolean'];
  createUserTodo: UserTodo;
  removeProviderFromTodo: Scalars['Boolean'];
  updateTodo: Todo;
  createDiscussionMessage: DiscussionMessage;
  createDiscussionThread: DiscussionThread;
  unsubscribeUserFromThread: Scalars['Boolean'];
  updateDiscussionMessage: DiscussionMessage;
  updateUserSubscriptionStatus: Scalars['Boolean'];
  createWebPhoto: WebPhoto;
  createWebVideo: WebVideo;
  deleteWebPhoto: WebPhoto;
  deleteWebVideo: WebVideo;
  updateWeb: Web;
  updateWebPhoto: WebPhoto;
  updateWebVideo: WebVideo;
  createWedding: Wedding;
  updateWedding: Wedding;
  setWeddingPremium: Wedding;
  generateWeddingVoucher: Wedding;
  createFapi: FapiOrder;
  createPipedrive: PipedriveDeal;
  createProvisionPipedrive: PipedriveDeal;
  updatePipedriveCustomFields: PipedriveDeal;
  updatePipedriveStatus: PipedriveDeal;
  createPipedriveSubscription: PipedriveDeal;
  createVoucher: Voucher;
  createTimeline: UserTimeline;
  createTimelineByUser: UserTimeline;
  createTimelineMessage: TimelineMessage;
};
export type Photo = {
  __typename?: 'Photo';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  showInInspiration: Scalars['Boolean'];
  showInGallery: Scalars['Boolean'];
  priorityInspiration?: Maybe<Scalars['Int']>;
  priorityGallery?: Maybe<Scalars['Int']>;
  provider: Provider;
  categories: Array<Category>;
  tags: Array<Tag>;
  createdAt: Scalars['DateTime'];
  liked?: Maybe<Scalars['Boolean']>;
};

export type PipedriveDeal = {
  __typename?: 'PipedriveDeal';
  id: Scalars['Int'];
  value?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type Planner = {
  __typename?: 'Planner';
  id: Scalars['ID'];
  numberOfGuests: Scalars['Int'];
  weddingDate: Scalars['DateTime'];
  pricing: PricingLevel;
};

export type PlannerCategory = {
  __typename?: 'PlannerCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  todos: Array<Todo>;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  image: Scalars['String'];
  imageAlt: Scalars['String'];
  perex: Scalars['String'];
  content: Scalars['String'];
  category: Category;
  user: UserPublic;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type PriceItem = {
  __typename?: 'PriceItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  priority?: Maybe<Scalars['Int']>;
};

export enum PricingLevel {}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  link: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  photo: Photo;
};

export type Provider = {
  __typename?: 'Provider';
  id: Scalars['ID'];
  oldId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  taxRegistrationNumber?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  contactEmail?: Maybe<Scalars['String']>;
  socialTwitter?: Maybe<Scalars['String']>;
  socialFacebook?: Maybe<Scalars['String']>;
  socialPinterest?: Maybe<Scalars['String']>;
  socialInstagram?: Maybe<Scalars['String']>;
  socialYoutube?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  cashback?: Maybe<Scalars['Int']>;
  membershipFrom?: Maybe<Scalars['DateTime']>;
  membershipTo?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  image: Scalars['String'];
  imageCropPosition: VerticalPosition;
  userLikesCount: Scalars['Int'];
  videos: Array<Video>;
  category: Category;
  photos: Array<Photo>;
  galleryPhotos: Array<Photo>;
  tags: Array<Tag>;
  stores: Array<Store>;
  ratings: Array<Rating>;
  ratingsAverage: Scalars['Float'];
  services: Array<Service>;
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
  salesman?: Maybe<User>;
  plannerUsers?: Maybe<Array<UserPublic>>;
  users?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  liked?: Maybe<Scalars['Boolean']>;
};

export type Rating = {
  __typename?: 'Rating';
  id: Scalars['ID'];
  numberOfStars: Scalars['Int'];
  content: Scalars['String'];
  providerFeedback?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  user?: Maybe<UserPublic>;
  provider: Provider;
  createdAt: Scalars['DateTime'];
  feedbackAt?: Maybe<Scalars['DateTime']>;
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  slug: Scalars['String'];
  locative: Scalars['String'];
  priceMultiplier: Scalars['Float'];
  cities: Array<City>;
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['ID'];
  name: Scalars['String'];
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  phone?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['Int']>;
  city: City;
  provider: Provider;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  seoTitle: Scalars['String'];
  seoDescription: Scalars['String'];
  seoShortText: Scalars['String'];
  seoKeywords: Array<Scalars['String']>;
  providers: Array<Provider>;
  photos: Array<Photo>;
  filter: Filter;
};

export type TemplateTodo = {
  __typename?: 'TemplateTodo';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  description: Scalars['String'];
  priceLow: Scalars['Int'];
  priceMedium: Scalars['Int'];
  priceHigh: Scalars['Int'];
  pricePerPerson: Scalars['Boolean'];
  providersCatalogLinkPriceLow?: Maybe<Scalars['String']>;
  providersCatalogLinkPriceMedium?: Maybe<Scalars['String']>;
  providersCatalogLinkPriceHigh?: Maybe<Scalars['String']>;
  inspirationPhotosLinkPriceLow?: Maybe<Scalars['String']>;
  inspirationPhotosLinkPriceMedium?: Maybe<Scalars['String']>;
  inspirationPhotosLinkPriceHigh?: Maybe<Scalars['String']>;
  plannerCategory: PlannerCategory;
  providerCategory?: Maybe<Category>;
  posts: Array<Post>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  doneAt?: Maybe<Scalars['DateTime']>;
  providers: Array<Provider>;
  dueAt?: Maybe<Scalars['DateTime']>;
  customPrice?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
};

export type Thread = {
  __typename?: 'Thread';
  id: Scalars['ID'];
  provider: Provider;
  user: User;
  messages: Array<Message>;
  hasUnseen: Scalars['Boolean'];
  offerPrice?: Maybe<Scalars['Int']>;
  offerDate?: Maybe<Scalars['DateTime']>;
  offerDescription?: Maybe<Scalars['String']>;
  offerWeddingAccepted?: Maybe<Scalars['DateTime']>;
  offerWeddingDenied?: Maybe<Scalars['DateTime']>;
  offerProviderAccepted?: Maybe<Scalars['DateTime']>;
  offerProviderDenied?: Maybe<Scalars['DateTime']>;
};

export type TimelineMessage = {
  __typename?: 'TimelineMessage';
  id: Scalars['ID'];
  content: Scalars['String'];
  user: UserPublic;
  timeline: UserTimeline;
  quotedMessage?: Maybe<TimelineMessage>;
  images?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Todo = TemplateTodo | UserTodo;
export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  wedding?: Maybe<Wedding>;
  providers: Array<Provider>;
  ratings: Array<Rating>;
  threads?: Maybe<Array<Thread>>;
  admin?: Maybe<Scalars['Boolean']>;
  likedPhotos: Array<Photo>;
  likedProviders: Array<Provider>;
  createdAt: Scalars['DateTime'];
};

export type UserPublic = {
  __typename?: 'UserPublic';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  wedding?: Maybe<Wedding>;
  ratings: Array<Rating>;
  likedPhotos: Array<Photo>;
  likedProviders: Array<Provider>;
  discussionThreads: Array<DiscussionThread>;
  discussionMessages: Array<DiscussionMessage>;
  timeline?: Maybe<UserTimeline>;
  providers: Array<Provider>;
};

export type UserTimeline = {
  __typename?: 'UserTimeline';
  id: Scalars['ID'];
  name: Scalars['String'];
  messages?: Maybe<Array<TimelineMessage>>;
  messagesCount?: Maybe<Scalars['Int']>;
  user: UserPublic;
  createdAt: Scalars['DateTime'];
};
export type UserTodo = {
  __typename?: 'UserTodo';
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  pricePerPerson: Scalars['Boolean'];
  doneAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueAt?: Maybe<Scalars['DateTime']>;
  providers: Array<Provider>;
};

export enum VerticalPosition {}

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID'];
  link: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  type: VideoType;
};

export enum VideoType {
  Vimeo = 'VIMEO',
}

export type Voucher = {
  __typename?: 'Voucher';
  id: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
};

export type Web = {
  __typename?: 'Web';
  id: Scalars['ID'];
  homepageTitlePosition: HorizontalPosition;
  homepageTitle: Scalars['String'];
  homepageSubtitle: Scalars['String'];
  homepageImage: Scalars['String'];
  homepageDescriptionTitle: Scalars['String'];
  homepageDescriptionText: Scalars['String'];
  galleryTitle: Scalars['String'];
  headingColor: Scalars['String'];
  navigationColor: Scalars['String'];
  wedding?: Maybe<Wedding>;
  photos: Array<WebPhoto>;
  videos: Array<WebVideo>;
};

export type WebPhoto = {
  __typename?: 'WebPhoto';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type WebVideo = {
  __typename?: 'WebVideo';
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  type: VideoType;
};

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type Wedding = {
  __typename?: 'Wedding';
  id: Scalars['ID'];
  partnerFirstName: Scalars['String'];
  partnerLastName: Scalars['String'];
  partnerSex: Sex;
  day: Scalars['DateTime'];
  placeName: Scalars['String'];
  placeLat?: Maybe<Scalars['Float']>;
  placeLng?: Maybe<Scalars['Float']>;
  avatar?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  user: UserPublic;
  web?: Maybe<Web>;
  premiumTo?: Maybe<Scalars['DateTime']>;
  voucherNumber?: Maybe<Scalars['Int']>;
  guests?: Maybe<Array<Guest>>;
  contacts?: Maybe<Array<WeddingContact>>;
  gifts?: Maybe<Array<WeddingGift>>;
};

export type WeddingContact = {
  __typename?: 'WeddingContact';
  id: Scalars['ID'];
  name: Scalars['String'];
  contact: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type WeddingGift = {
  __typename?: 'WeddingGift';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  reserved?: Maybe<Scalars['String']>;
};

export type LoginMutationVariables = {
  email: Scalars['String'];
  adminPanel: Scalars['Boolean'];
};

export type LoginMutation = { __typename?: 'Mutation' } & Pick<Mutation, 'login'>;

export type DeletePhotoMutationVariables = {
  id: Scalars['ID'];
};

export type DeletePhotoMutation = { __typename?: 'Mutation' } & {
  deletePhoto: { __typename?: 'Photo' } & Pick<Photo, 'id'>;
};

export type CreatePhotoMutationVariables = {
  providerId: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  showInInspiration: Scalars['Boolean'];
  showInGallery: Scalars['Boolean'];
  priorityInspiration?: Maybe<Scalars['Int']>;
  priorityGallery?: Maybe<Scalars['Int']>;
  categories: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type CreatePhotoMutation = { __typename?: 'Mutation' } & {
  createPhoto: { __typename?: 'Photo' } & Pick<Photo, 'id'>;
};

export type UpdatePhotoMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
  link: Scalars['String'];
  showInInspiration: Scalars['Boolean'];
  showInGallery: Scalars['Boolean'];
  priorityInspiration?: Maybe<Scalars['Int']>;
  priorityGallery?: Maybe<Scalars['Int']>;
  categories: Array<Scalars['ID']>;
  tags: Array<Scalars['ID']>;
};

export type UpdatePhotoMutation = { __typename?: 'Mutation' } & {
  updatePhoto: { __typename?: 'Photo' } & Pick<Photo, 'id'>;
};

export type CreatePostMutationVariables = {
  name: Scalars['String'];
  slug: Scalars['String'];
  user: Scalars['ID'];
  category: Scalars['ID'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  image: Scalars['String'];
  imageAlt: Scalars['String'];
  perex: Scalars['String'];
  content: Scalars['String'];
};

export type CreatePostMutation = { __typename?: 'Mutation' } & {
  createPost: { __typename?: 'Post' } & Pick<Post, 'id'>;
};

export type UpdatePostMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  user: Scalars['ID'];
  category: Scalars['ID'];
  publishedAt?: Maybe<Scalars['DateTime']>;
  image: Scalars['String'];
  imageAlt: Scalars['String'];
  perex: Scalars['String'];
  content: Scalars['String'];
};

export type UpdatePostMutation = { __typename?: 'Mutation' } & {
  updatePost: { __typename?: 'Post' } & Pick<Post, 'id'>;
};

export type DeletePostMutationVariables = {
  id: Scalars['ID'];
};

export type DeletePostMutation = { __typename?: 'Mutation' } & {
  deletePost: { __typename?: 'Post' } & Pick<Post, 'id'>;
};

export type CreateProductMutationVariables = {
  providerId: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  link: Scalars['String'];
  photoId: Scalars['ID'];
};

export type CreateProductMutation = { __typename?: 'Mutation' } & {
  createProduct: { __typename?: 'Product' } & Pick<Product, 'id'>;
};

export type UpdateProductMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  link: Scalars['String'];
  photoId: Scalars['ID'];
};

export type UpdateProductMutation = { __typename?: 'Mutation' } & {
  updateProduct: { __typename?: 'Product' } & Pick<Product, 'id'>;
};

export type DeleteProductMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteProductMutation = { __typename?: 'Mutation' } & {
  deleteProduct: { __typename?: 'Product' } & Pick<Product, 'id'>;
};

export type CreateServiceMutationVariables = {
  providerId: Scalars['ID'];
  name: Scalars['String'];
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type CreateServiceMutation = { __typename?: 'Mutation' } & {
  createService: { __typename?: 'Service' } & Pick<Service, 'id'>;
};

export type UpdateServiceMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
};

export type UpdateServiceMutation = { __typename?: 'Mutation' } & {
  updateService: { __typename?: 'Service' } & Pick<Service, 'id'>;
};

export type DeleteServiceMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteServiceMutation = { __typename?: 'Mutation' } & {
  deleteService: { __typename?: 'Service' } & Pick<Service, 'id'>;
};

export type CreateProviderMutationVariables = {
  name: Scalars['String'];
  slug: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  taxRegistrationNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  socialTwitter?: Maybe<Scalars['String']>;
  socialFacebook?: Maybe<Scalars['String']>;
  socialPinterest?: Maybe<Scalars['String']>;
  socialInstagram?: Maybe<Scalars['String']>;
  socialYoutube?: Maybe<Scalars['String']>;
  membershipFrom?: Maybe<Scalars['DateTime']>;
  membershipTo?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  category: Scalars['ID'];
  tags: Array<Scalars['ID']>;
  image: Scalars['String'];
  imageCropPosition: VerticalPosition;
  cashback?: Maybe<Scalars['Int']>;
  salesman: Scalars['ID'];
};

export type CreateProviderMutation = { __typename?: 'Mutation' } & {
  createProvider: { __typename?: 'Provider' } & Pick<Provider, 'id'>;
};

export type UpdateProviderMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  taxRegistrationNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  socialTwitter?: Maybe<Scalars['String']>;
  socialFacebook?: Maybe<Scalars['String']>;
  socialPinterest?: Maybe<Scalars['String']>;
  socialInstagram?: Maybe<Scalars['String']>;
  socialYoutube?: Maybe<Scalars['String']>;
  membershipFrom?: Maybe<Scalars['DateTime']>;
  membershipTo?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  category: Scalars['ID'];
  tags: Array<Scalars['ID']>;
  image: Scalars['String'];
  imageCropPosition: VerticalPosition;
  cashback?: Maybe<Scalars['Int']>;
  salesman: Scalars['ID'];
};

export type UpdateProviderMutation = { __typename?: 'Mutation' } & {
  updateProvider: { __typename?: 'Provider' } & Pick<Provider, 'id'>;
};

export type DeleteVideoMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteVideoMutation = { __typename?: 'Mutation' } & {
  deleteVideo: { __typename?: 'Video' } & Pick<Video, 'id'>;
};

export type CreateStoreMutationVariables = {
  providerId: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  priority?: Maybe<Scalars['Int']>;
  cityId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['Int']>;
};

export type CreateStoreMutation = { __typename?: 'Mutation' } & {
  createStore: { __typename?: 'Store' } & Pick<Store, 'id'>;
};

export type UpdateStoreMutationVariables = {
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  priority?: Maybe<Scalars['Int']>;
  cityId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  web?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['Int']>;
};

export type UpdateStoreMutation = { __typename?: 'Mutation' } & {
  updateStore: { __typename?: 'Store' } & Pick<Store, 'id'>;
};

export type DeleteProviderMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteProviderMutation = { __typename?: 'Mutation' } & {
  deleteProvider: { __typename?: 'Provider' } & Pick<Provider, 'id'>;
};

export type DeleteRatingMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteRatingMutation = { __typename?: 'Mutation' } & {
  deleteRating: { __typename?: 'Rating' } & Pick<Rating, 'id'>;
};

export type DeleteStoreMutationVariables = {
  id: Scalars['ID'];
};

export type DeleteStoreMutation = { __typename?: 'Mutation' } & {
  deleteStore: { __typename?: 'Store' } & Pick<Store, 'id'>;
};

export type CreateVideoMutationVariables = {
  providerId: Scalars['ID'];
  link: Scalars['String'];
  type: VideoType;
  priority?: Maybe<Scalars['Int']>;
};

export type CreateVideoMutation = { __typename?: 'Mutation' } & {
  createVideo: { __typename?: 'Video' } & Pick<Video, 'id'>;
};

export type UpdateVideoMutationVariables = {
  id: Scalars['ID'];
  link: Scalars['String'];
  type: VideoType;
  priority?: Maybe<Scalars['Int']>;
};

export type UpdateVideoMutation = { __typename?: 'Mutation' } & {
  updateVideo: { __typename?: 'Video' } & Pick<Video, 'id'>;
};

export type AddUserToProviderMutationVariables = {
  provider: Scalars['ID'];
  user: Scalars['ID'];
};

export type AddUserToProviderMutation = { __typename?: 'Mutation' } & {
  addUserToProvider: { __typename?: 'Provider' } & Pick<Provider, 'id'>;
};

export type RemoveUserFromProviderMutationVariables = {
  provider: Scalars['ID'];
  user: Scalars['ID'];
};

export type RemoveUserFromProviderMutation = { __typename?: 'Mutation' } & {
  removeUserFromProvider: { __typename?: 'Provider' } & Pick<Provider, 'id'>;
};

export type CreateUserMutationVariables = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  admin: Scalars['Boolean'];
};

export type CreateUserMutation = { __typename?: 'Mutation' } & {
  createUser: { __typename?: 'User' } & Pick<User, 'id'>;
};

export type UpdateUserMutationVariables = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  admin: Scalars['Boolean'];
};

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'User' } & Pick<User, 'id'>;
};

export type CreateRatingMutationVariables = {
  numberOfStars: Scalars['Int'];
  content: Scalars['String'];
  userName: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  provider: Scalars['ID'];
};

export type CreateRatingMutation = { __typename?: 'Mutation' } & {
  adminCreateRating: { __typename?: 'Rating' } & Pick<Rating, 'id'>;
};

export type UpdateRatingMutationVariables = {
  id: Scalars['ID'];
  numberOfStars: Scalars['Int'];
  content: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  providerFeedback?: Maybe<Scalars['String']>;
};

export type UpdateRatingMutation = { __typename?: 'Mutation' } & {
  updateRating: { __typename?: 'Rating' } & Pick<Rating, 'id'>;
};
export type PhotoQueryVariables = {
  id: Scalars['ID'];
};

export type PhotoQuery = { __typename?: 'Query' } & {
  photo: { __typename?: 'Photo' } & Pick<
    Photo,
    | 'id'
    | 'name'
    | 'link'
    | 'createdAt'
    | 'showInInspiration'
    | 'showInGallery'
    | 'priorityGallery'
    | 'priorityInspiration'
  > & {
      categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
      tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name'>>;
      provider: { __typename?: 'Provider' } & Pick<Provider, 'id' | 'name' | 'slug'>;
    };
};
export type InspirationPhotosQueryVariables = {};

export type InspirationPhotosQuery = { __typename?: 'Query' } & {
  photos: Array<
    { __typename?: 'Photo' } & Pick<Photo, 'id' | 'name' | 'link' | 'showInInspiration'> & {
        categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
        provider: { __typename?: 'Provider' } & Pick<Provider, 'id' | 'name'>;
      }
  >;
};

export type ProviderQueryVariables = {
  id: Scalars['ID'];
};

export type ProviderQuery = { __typename?: 'Query' } & {
  provider: { __typename?: 'Provider' } & Pick<
    Provider,
    | 'id'
    | 'oldId'
    | 'cashback'
    | 'name'
    | 'slug'
    | 'registrationNumber'
    | 'taxRegistrationNumber'
    | 'description'
    | 'contactEmail'
    | 'socialTwitter'
    | 'socialFacebook'
    | 'socialPinterest'
    | 'socialInstagram'
    | 'socialYoutube'
    | 'membershipFrom'
    | 'membershipTo'
    | 'priority'
    | 'published'
    | 'image'
    | 'imageCropPosition'
    | 'userLikesCount'
    | 'createdAt'
    | 'updatedAt'
  > & {
      videos: Array<{ __typename?: 'Video' } & Pick<Video, 'id' | 'link' | 'type' | 'priority'>>;
      stores: Array<
        { __typename?: 'Store' } & Pick<Store, 'id' | 'name' | 'address' | 'priority'> & {
            city: { __typename?: 'City' } & Pick<City, 'id' | 'name'>;
          }
      >;
      salesman: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName'>>;
      category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name'>;
      tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name'>>;
      products: Array<
        { __typename?: 'Product' } & Pick<Product, 'id' | 'name' | 'price' | 'priority'> & {
            photo: { __typename?: 'Photo' } & Pick<Photo, 'id' | 'name' | 'link'>;
          }
      >;
      services: Array<
        { __typename?: 'Service' } & Pick<Service, 'id' | 'name' | 'priceFrom' | 'priceTo' | 'priority' | 'description'>
      >;
      users: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email'>>>;
      photos: Array<
        { __typename?: 'Photo' } & Pick<
          Photo,
          'id' | 'name' | 'link' | 'showInInspiration' | 'showInGallery' | 'priorityInspiration' | 'priorityGallery'
        > & {
            categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id' | 'name'>>;
            tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name'>>;
          }
      >;
      ratings: Array<
        { __typename?: 'Rating' } & Pick<
          Rating,
          'id' | 'numberOfStars' | 'content' | 'providerFeedback' | 'feedbackAt' | 'userName'
        > & { user: Maybe<{ __typename?: 'UserPublic' } & Pick<UserPublic, 'id' | 'firstName' | 'lastName'>> }
      >;
      plannerUsers: Maybe<Array<{ __typename?: 'UserPublic' } & Pick<UserPublic, 'id' | 'firstName' | 'lastName'>>>;
    };
};

export type ProvidersQueryVariables = {};

export type ProvidersQuery = { __typename?: 'Query' } & {
  providers: Array<
    { __typename?: 'Provider' } & Pick<Provider, 'id' | 'name' | 'image' | 'published'> & {
        users: Maybe<Array<{ __typename?: 'User' } & Pick<User, 'email'>>>;
      }
  >;
};

export type ProviderAnalyticsQueryVariables = {
  providerSlug: Scalars['String'];
};

export type ProviderAnalyticsQuery = { __typename?: 'Query' } & {
  providerAnalytics: { __typename?: 'Analytics2' } & Pick<
    Analytics2,
    | 'clicked_gps'
    | 'dial_email'
    | 'go_to_about'
    | 'go_to_contacts'
    | 'go_to_facebook'
    | 'go_to_gallery'
    | 'go_to_instagram'
    | 'go_to_products'
    | 'go_to_ratings'
    | 'go_to_services'
    | 'go_to_twitter'
    | 'go_to_videos'
    | 'go_to_web'
    | 'go_to_youtube'
    | 'hide_message_form'
    | 'play_video'
    | 'send_email'
    | 'show_email'
    | 'show_less_product_info'
    | 'show_message_form'
    | 'show_message_form_sidebar'
    | 'show_more_contacts'
    | 'show_more_product_info'
    | 'show_phone'
    | 'show_photo'
    | 'show_web'
    | 'visit'
  >;
};

export type CategoriesQueryVariables = {};

export type CategoriesQuery = { __typename?: 'Query' } & {
  categories: Array<
    { __typename?: 'Category' } & Pick<Category, 'id' | 'name'> & {
        filters: Array<
          { __typename?: 'Filter' } & Pick<Filter, 'id' | 'name'> & {
              tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name'>>;
            }
        >;
      }
  >;
};

export type CategoriesPriceQueryVariables = {};

export type CategoriesPriceQuery = { __typename?: 'Query' } & {
  categories: Array<
    { __typename?: 'Category' } & Pick<
      Category,
      'id' | 'name' | 'slug' | 'priceStart' | 'priceStandard' | 'priceExtra' | 'pricePremium'
    >
  >;
};
export type FiltersQueryVariables = {};

export type FiltersQuery = { __typename?: 'Query' } & {
  filters: Array<
    { __typename?: 'Filter' } & Pick<Filter, 'id' | 'name' | 'activeForProvider' | 'activeForInspiration'> & {
        categories: Array<{ __typename?: 'Category' } & Pick<Category, 'id'>>;
        tags: Array<{ __typename?: 'Tag' } & Pick<Tag, 'id' | 'name' | 'slug'>>;
      }
  >;
};

export type PostsQueryVariables = {};

export type PostsQuery = { __typename?: 'Query' } & {
  posts: Array<{ __typename?: 'Post' } & Pick<Post, 'id' | 'name' | 'image' | 'slug' | 'publishedAt'>>;
};

export type PostQueryVariables = {
  id: Scalars['ID'];
};

export type PostQuery = { __typename?: 'Query' } & {
  post: { __typename?: 'Post' } & Pick<
    Post,
    'id' | 'name' | 'slug' | 'image' | 'imageAlt' | 'publishedAt' | 'perex' | 'content'
  > & {
      category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name'>;
      user: { __typename?: 'UserPublic' } & Pick<UserPublic, 'id' | 'firstName' | 'lastName'>;
    };
};

export type UsersQueryVariables = {
  adminOnly?: Maybe<Scalars['Boolean']>;
};

export type UsersQuery = { __typename?: 'Query' } & {
  users: Array<
    { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'admin'> & {
        providers: Array<{ __typename?: 'Provider' } & Pick<Provider, 'id' | 'name'>>;
      }
  >;
};

export type UserQueryVariables = {
  id: Scalars['ID'];
};

export type UserQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'User' } & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'admin'>;
};

export type ProductQueryVariables = {
  id: Scalars['ID'];
};

export type ProductQuery = { __typename?: 'Query' } & {
  product: { __typename?: 'Product' } & Pick<Product, 'id' | 'name' | 'priority' | 'price' | 'description' | 'link'> & {
      photo: { __typename?: 'Photo' } & Pick<Photo, 'id'>;
    };
};

export type ServiceQueryVariables = {
  id: Scalars['ID'];
};

export type ServiceQuery = { __typename?: 'Query' } & {
  service: { __typename?: 'Service' } & Pick<
    Service,
    'id' | 'name' | 'priceFrom' | 'priceTo' | 'priority' | 'description'
  >;
};

export type VideoQueryVariables = {
  id: Scalars['ID'];
};

export type VideoQuery = { __typename?: 'Query' } & {
  video: { __typename?: 'Video' } & Pick<Video, 'id' | 'link' | 'type' | 'priority'>;
};

export type StoreQueryVariables = {
  id: Scalars['ID'];
};

export type StoreQuery = { __typename?: 'Query' } & {
  store: { __typename?: 'Store' } & Pick<
    Store,
    'id' | 'name' | 'address' | 'lat' | 'lng' | 'phone' | 'web' | 'email' | 'openingHours' | 'priority' | 'zipCode'
  > & {
      city: { __typename?: 'City' } & Pick<City, 'id'>;
      provider: { __typename?: 'Provider' } & Pick<Provider, 'id'>;
    };
};

export type RatingQueryVariables = {
  id: Scalars['ID'];
};

export type RatingQuery = { __typename?: 'Query' } & {
  rating: { __typename?: 'Rating' } & Pick<
    Rating,
    'id' | 'numberOfStars' | 'userName' | 'content' | 'providerFeedback' | 'feedbackAt' | 'createdAt'
  > & { user: Maybe<{ __typename?: 'UserPublic' } & Pick<UserPublic, 'id' | 'firstName' | 'lastName'>> };
};

export type CitiesQueryVariables = {};

export type CitiesQuery = { __typename?: 'Query' } & {
  cities: Array<{ __typename?: 'City' } & Pick<City, 'id' | 'name'>>;
};

export type RegionsQueryVariables = {};

export type RegionsQuery = { __typename?: 'Query' } & {
  regions: Array<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name' | 'shortName' | 'priceMultiplier'>>;
};
export type PriceItemsQueryVariables = {};

export type PriceItemsQuery = { __typename?: 'Query' } & {
  priceItems: Array<{ __typename?: 'PriceItem' } & Pick<PriceItem, 'id' | 'name' | 'price' | 'priority'>>;
};

export const LoginDocument = gql`
  mutation login($email: String!, $adminPanel: Boolean!) {
    login(email: $email, adminPanel: $adminPanel)
  }
`;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      adminPanel: // value for 'adminPanel'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
}
export const DeletePhotoDocument = gql`
  mutation deletePhoto($id: ID!) {
    deletePhoto(id: $id) {
      id
    }
  }
`;
export type DeletePhotoComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeletePhotoMutation, DeletePhotoMutationVariables>,
  'mutation'
>;

export const DeletePhotoComponent = (props: DeletePhotoComponentProps) => (
  <ApolloComponents.Mutation<DeletePhotoMutation, DeletePhotoMutationVariables>
    mutation={DeletePhotoDocument}
    {...props}
    children={props.children}
  />
);
export const CreatePhotoDocument = gql`
  mutation createPhoto(
    $providerId: ID!
    $name: String!
    $link: String!
    $showInInspiration: Boolean!
    $showInGallery: Boolean!
    $priorityInspiration: Int
    $priorityGallery: Int
    $categories: [ID!]!
    $tags: [ID!]!
  ) {
    createPhoto(
      providerId: $providerId
      name: $name
      link: $link
      showInInspiration: $showInInspiration
      showInGallery: $showInGallery
      priorityInspiration: $priorityInspiration
      priorityGallery: $priorityGallery
      categories: $categories
      tags: $tags
    ) {
      id
    }
  }
`;

/**
 * __useCreatePhotoMutation__
 *
 * To run a mutation, you first call `useCreatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhotoMutation, { data, loading, error }] = useCreatePhotoMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      showInInspiration: // value for 'showInInspiration'
 *      showInGallery: // value for 'showInGallery'
 *      priorityInspiration: // value for 'priorityInspiration'
 *      priorityGallery: // value for 'priorityGallery'
 *      categories: // value for 'categories'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreatePhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePhotoMutation, CreatePhotoMutationVariables>,
) {
  return Apollo.useMutation<CreatePhotoMutation, CreatePhotoMutationVariables>(CreatePhotoDocument, baseOptions);
}

export const UpdatePhotoDocument = gql`
  mutation updatePhoto(
    $id: ID!
    $name: String!
    $link: String!
    $showInInspiration: Boolean!
    $showInGallery: Boolean!
    $priorityInspiration: Int
    $priorityGallery: Int
    $categories: [ID!]!
    $tags: [ID!]!
  ) {
    updatePhoto(
      id: $id
      name: $name
      link: $link
      showInInspiration: $showInInspiration
      showInGallery: $showInGallery
      priorityInspiration: $priorityInspiration
      priorityGallery: $priorityGallery
      categories: $categories
      tags: $tags
    ) {
      id
    }
  }
`;

/**
 * __useUpdatePhotoMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoMutation, { data, loading, error }] = useUpdatePhotoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      link: // value for 'link'
 *      showInInspiration: // value for 'showInInspiration'
 *      showInGallery: // value for 'showInGallery'
 *      priorityInspiration: // value for 'priorityInspiration'
 *      priorityGallery: // value for 'priorityGallery'
 *      categories: // value for 'categories'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpdatePhotoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>,
) {
  return Apollo.useMutation<UpdatePhotoMutation, UpdatePhotoMutationVariables>(UpdatePhotoDocument, baseOptions);
}
export const CreatePostDocument = gql`
  mutation createPost(
    $name: String!
    $slug: String!
    $user: ID!
    $category: ID!
    $publishedAt: DateTime
    $image: String!
    $imageAlt: String!
    $perex: String!
    $content: String!
  ) {
    createPost(
      name: $name
      slug: $slug
      user: $user
      category: $category
      publishedAt: $publishedAt
      image: $image
      imageAlt: $imageAlt
      perex: $perex
      content: $content
    ) {
      id
    }
  }
`;
/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      user: // value for 'user'
 *      category: // value for 'category'
 *      publishedAt: // value for 'publishedAt'
 *      image: // value for 'image'
 *      imageAlt: // value for 'imageAlt'
 *      perex: // value for 'perex'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>,
) {
  return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
}
export const UpdatePostDocument = gql`
  mutation updatePost(
    $id: ID!
    $name: String!
    $slug: String!
    $user: ID!
    $category: ID!
    $publishedAt: DateTime
    $image: String!
    $imageAlt: String!
    $perex: String!
    $content: String!
  ) {
    updatePost(
      id: $id
      name: $name
      slug: $slug
      user: $user
      category: $category
      publishedAt: $publishedAt
      image: $image
      imageAlt: $imageAlt
      perex: $perex
      content: $content
    ) {
      id
    }
  }
`;
/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      user: // value for 'user'
 *      category: // value for 'category'
 *      publishedAt: // value for 'publishedAt'
 *      image: // value for 'image'
 *      imageAlt: // value for 'imageAlt'
 *      perex: // value for 'perex'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>,
) {
  return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, baseOptions);
}
export const DeletePostDocument = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      id
    }
  }
`;
export type DeletePostComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeletePostMutation, DeletePostMutationVariables>,
  'mutation'
>;

export const DeletePostComponent = (props: DeletePostComponentProps) => (
  <ApolloComponents.Mutation<DeletePostMutation, DeletePostMutationVariables>
    mutation={DeletePostDocument}
    {...props}
  />
);
export const CreateProductDocument = gql`
  mutation createProduct(
    $providerId: ID!
    $name: String!
    $price: Int
    $description: String!
    $priority: Int
    $link: String!
    $photoId: ID!
  ) {
    createProduct(
      providerId: $providerId
      name: $name
      price: $price
      description: $description
      priority: $priority
      link: $link
      photoId: $photoId
    ) {
      id
    }
  }
`;
/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      link: // value for 'link'
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>,
) {
  return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, baseOptions);
}
export const UpdateProductDocument = gql`
  mutation updateProduct(
    $id: ID!
    $name: String!
    $price: Int
    $description: String!
    $priority: Int
    $link: String!
    $photoId: ID!
  ) {
    updateProduct(
      id: $id
      name: $name
      price: $price
      description: $description
      priority: $priority
      link: $link
      photoId: $photoId
    ) {
      id
    }
  }
`;
/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      link: // value for 'link'
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>,
) {
  return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
}
export const DeleteProductDocument = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
    }
  }
`;
export type DeleteProductComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeleteProductMutation, DeleteProductMutationVariables>,
  'mutation'
>;

export const DeleteProductComponent = (props: DeleteProductComponentProps) => (
  <ApolloComponents.Mutation<DeleteProductMutation, DeleteProductMutationVariables>
    mutation={DeleteProductDocument}
    {...props}
  />
);
export const CreateServiceDocument = gql`
  mutation createService(
    $providerId: ID!
    $name: String!
    $priceFrom: Int
    $priceTo: Int
    $description: String!
    $priority: Int
  ) {
    createService(
      providerId: $providerId
      name: $name
      priceFrom: $priceFrom
      priceTo: $priceTo
      description: $description
      priority: $priority
    ) {
      id
    }
  }
`;
/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      name: // value for 'name'
 *      priceFrom: // value for 'priceFrom'
 *      priceTo: // value for 'priceTo'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useCreateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>,
) {
  return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, baseOptions);
}
export const UpdateServiceDocument = gql`
  mutation updateService(
    $id: ID!
    $name: String!
    $priceFrom: Int
    $priceTo: Int
    $description: String!
    $priority: Int
  ) {
    updateService(
      id: $id
      name: $name
      priceFrom: $priceFrom
      priceTo: $priceTo
      description: $description
      priority: $priority
    ) {
      id
    }
  }
`;
/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      priceFrom: // value for 'priceFrom'
 *      priceTo: // value for 'priceTo'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>,
) {
  return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, baseOptions);
}
export const DeleteServiceDocument = gql`
  mutation deleteService($id: ID!) {
    deleteService(id: $id) {
      id
    }
  }
`;
export type DeleteServiceComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeleteServiceMutation, DeleteServiceMutationVariables>,
  'mutation'
>;

export const DeleteServiceComponent = (props: DeleteServiceComponentProps) => (
  <ApolloComponents.Mutation<DeleteServiceMutation, DeleteServiceMutationVariables>
    mutation={DeleteServiceDocument}
    {...props}
  />
);
export const CreateProviderDocument = gql`
  mutation createProvider(
    $name: String!
    $slug: String!
    $registrationNumber: String
    $taxRegistrationNumber: String
    $contactEmail: String
    $description: String!
    $socialTwitter: String
    $socialFacebook: String
    $socialPinterest: String
    $socialInstagram: String
    $socialYoutube: String
    $membershipFrom: DateTime
    $membershipTo: DateTime
    $priority: Int
    $published: Boolean!
    $category: ID!
    $tags: [ID!]!
    $image: String!
    $imageCropPosition: VerticalPosition!
    $cashback: Int
    $salesman: ID!
  ) {
    createProvider(
      name: $name
      slug: $slug
      registrationNumber: $registrationNumber
      taxRegistrationNumber: $taxRegistrationNumber
      contactEmail: $contactEmail
      description: $description
      socialTwitter: $socialTwitter
      socialFacebook: $socialFacebook
      socialPinterest: $socialPinterest
      socialInstagram: $socialInstagram
      socialYoutube: $socialYoutube
      membershipFrom: $membershipFrom
      membershipTo: $membershipTo
      priority: $priority
      published: $published
      category: $category
      tags: $tags
      image: $image
      imageCropPosition: $imageCropPosition
      cashback: $cashback
      salesman: $salesman
    ) {
      id
    }
  }
`;
/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      registrationNumber: // value for 'registrationNumber'
 *      taxRegistrationNumber: // value for 'taxRegistrationNumber'
 *      contactEmail: // value for 'contactEmail'
 *      description: // value for 'description'
 *      socialTwitter: // value for 'socialTwitter'
 *      socialFacebook: // value for 'socialFacebook'
 *      socialPinterest: // value for 'socialPinterest'
 *      socialInstagram: // value for 'socialInstagram'
 *      socialYoutube: // value for 'socialYoutube'
 *      membershipFrom: // value for 'membershipFrom'
 *      membershipTo: // value for 'membershipTo'
 *      priority: // value for 'priority'
 *      published: // value for 'published'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      image: // value for 'image'
 *      imageCropPosition: // value for 'imageCropPosition'
 *      cashback: // value for 'cashback'
 *      salesman: // value for 'salesman'
 *   },
 * });
 */
export function useCreateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProviderMutation, CreateProviderMutationVariables>,
) {
  return Apollo.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(
    CreateProviderDocument,
    baseOptions,
  );
}
export const UpdateProviderDocument = gql`
  mutation updateProvider(
    $id: ID!
    $name: String!
    $slug: String!
    $registrationNumber: String
    $taxRegistrationNumber: String
    $contactEmail: String
    $description: String
    $socialTwitter: String
    $socialFacebook: String
    $socialPinterest: String
    $socialInstagram: String
    $socialYoutube: String
    $membershipFrom: DateTime
    $membershipTo: DateTime
    $priority: Int
    $published: Boolean!
    $category: ID!
    $tags: [ID!]!
    $image: String!
    $imageCropPosition: VerticalPosition!
    $cashback: Int
    $salesman: ID!
  ) {
    updateProvider(
      id: $id
      name: $name
      slug: $slug
      registrationNumber: $registrationNumber
      taxRegistrationNumber: $taxRegistrationNumber
      contactEmail: $contactEmail
      description: $description
      socialTwitter: $socialTwitter
      socialFacebook: $socialFacebook
      socialPinterest: $socialPinterest
      socialInstagram: $socialInstagram
      socialYoutube: $socialYoutube
      membershipFrom: $membershipFrom
      membershipTo: $membershipTo
      priority: $priority
      published: $published
      category: $category
      tags: $tags
      image: $image
      imageCropPosition: $imageCropPosition
      cashback: $cashback
      salesman: $salesman
    ) {
      id
    }
  }
`;
/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      slug: // value for 'slug'
 *      registrationNumber: // value for 'registrationNumber'
 *      taxRegistrationNumber: // value for 'taxRegistrationNumber'
 *      contactEmail: // value for 'contactEmail'
 *      description: // value for 'description'
 *      socialTwitter: // value for 'socialTwitter'
 *      socialFacebook: // value for 'socialFacebook'
 *      socialPinterest: // value for 'socialPinterest'
 *      socialInstagram: // value for 'socialInstagram'
 *      socialYoutube: // value for 'socialYoutube'
 *      membershipFrom: // value for 'membershipFrom'
 *      membershipTo: // value for 'membershipTo'
 *      priority: // value for 'priority'
 *      published: // value for 'published'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *      image: // value for 'image'
 *      imageCropPosition: // value for 'imageCropPosition'
 *      cashback: // value for 'cashback'
 *      salesman: // value for 'salesman'
 *   },
 * });
 */
export function useUpdateProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProviderMutation, UpdateProviderMutationVariables>,
) {
  return Apollo.useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(
    UpdateProviderDocument,
    baseOptions,
  );
}
export const DeleteVideoDocument = gql`
  mutation deleteVideo($id: ID!) {
    deleteVideo(id: $id) {
      id
    }
  }
`;
export type DeleteVideoComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeleteVideoMutation, DeleteVideoMutationVariables>,
  'mutation'
>;

export const DeleteVideoComponent = (props: DeleteVideoComponentProps) => (
  <ApolloComponents.Mutation<DeleteVideoMutation, DeleteVideoMutationVariables>
    mutation={DeleteVideoDocument}
    {...props}
  />
);
export const CreateStoreDocument = gql`
  mutation createStore(
    $providerId: ID!
    $name: String!
    $address: String!
    $lat: Float!
    $lng: Float!
    $priority: Int
    $cityId: ID!
    $phone: String
    $web: String
    $email: String
    $openingHours: String
    $zipCode: Int
  ) {
    createStore(
      providerId: $providerId
      name: $name
      address: $address
      lat: $lat
      lng: $lng
      priority: $priority
      cityId: $cityId
      phone: $phone
      web: $web
      email: $email
      openingHours: $openingHours
      zipCode: $zipCode
    ) {
      id
    }
  }
`;
/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      priority: // value for 'priority'
 *      cityId: // value for 'cityId'
 *      phone: // value for 'phone'
 *      web: // value for 'web'
 *      email: // value for 'email'
 *      openingHours: // value for 'openingHours'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useCreateStoreMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>,
) {
  return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, baseOptions);
}
export const UpdateStoreDocument = gql`
  mutation updateStore(
    $id: ID!
    $name: String!
    $address: String!
    $lat: Float!
    $lng: Float!
    $priority: Int
    $cityId: ID!
    $phone: String
    $web: String
    $email: String
    $openingHours: String
    $zipCode: Int
  ) {
    updateStore(
      id: $id
      name: $name
      address: $address
      lat: $lat
      lng: $lng
      priority: $priority
      cityId: $cityId
      phone: $phone
      web: $web
      email: $email
      openingHours: $openingHours
      zipCode: $zipCode
    ) {
      id
    }
  }
`;
/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      priority: // value for 'priority'
 *      cityId: // value for 'cityId'
 *      phone: // value for 'phone'
 *      web: // value for 'web'
 *      email: // value for 'email'
 *      openingHours: // value for 'openingHours'
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useUpdateStoreMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>,
) {
  return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, baseOptions);
}
export const DeleteProviderDocument = gql`
  mutation deleteProvider($id: ID!) {
    deleteProvider(id: $id) {
      id
    }
  }
`;
export type DeleteProviderComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeleteProviderMutation, DeleteProviderMutationVariables>,
  'mutation'
>;

export const DeleteProviderComponent = (props: DeleteProviderComponentProps) => (
  <ApolloComponents.Mutation<DeleteProviderMutation, DeleteProviderMutationVariables>
    mutation={DeleteProviderDocument}
    {...props}
  />
);
export const DeleteRatingDocument = gql`
  mutation deleteRating($id: ID!) {
    deleteRating(id: $id) {
      id
    }
  }
`;
export type DeleteRatingComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeleteRatingMutation, DeleteRatingMutationVariables>,
  'mutation'
>;

export const DeleteRatingComponent = (props: DeleteRatingComponentProps) => (
  <ApolloComponents.Mutation<DeleteRatingMutation, DeleteRatingMutationVariables>
    mutation={DeleteRatingDocument}
    {...props}
  />
);
export const DeleteStoreDocument = gql`
  mutation deleteStore($id: ID!) {
    deleteStore(id: $id) {
      id
    }
  }
`;
export type DeleteStoreComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<DeleteStoreMutation, DeleteStoreMutationVariables>,
  'mutation'
>;

export const DeleteStoreComponent = (props: DeleteStoreComponentProps) => (
  <ApolloComponents.Mutation<DeleteStoreMutation, DeleteStoreMutationVariables>
    mutation={DeleteStoreDocument}
    {...props}
  />
);
export const CreateVideoDocument = gql`
  mutation createVideo($providerId: ID!, $link: String!, $type: VideoType!, $priority: Int) {
    createVideo(providerId: $providerId, link: $link, type: $type, priority: $priority) {
      id
    }
  }
`;
/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useCreateVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVideoMutation, CreateVideoMutationVariables>,
) {
  return Apollo.useMutation<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument, baseOptions);
}
export const UpdateVideoDocument = gql`
  mutation updateVideo($id: ID!, $link: String!, $type: VideoType!, $priority: Int) {
    updateVideo(id: $id, link: $link, type: $type, priority: $priority) {
      id
    }
  }
`;
/**
 * __useUpdateVideoMutation__
 *
 * To run a mutation, you first call `useUpdateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVideoMutation, { data, loading, error }] = useUpdateVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      link: // value for 'link'
 *      type: // value for 'type'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdateVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVideoMutation, UpdateVideoMutationVariables>,
) {
  return Apollo.useMutation<UpdateVideoMutation, UpdateVideoMutationVariables>(UpdateVideoDocument, baseOptions);
}
export const AddUserToProviderDocument = gql`
  mutation addUserToProvider($provider: ID!, $user: ID!) {
    addUserToProvider(provider: $provider, user: $user) {
      id
    }
  }
`;
/**
 * __useAddUserToProviderMutation__
 *
 * To run a mutation, you first call `useAddUserToProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToProviderMutation, { data, loading, error }] = useAddUserToProviderMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAddUserToProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUserToProviderMutation, AddUserToProviderMutationVariables>,
) {
  return Apollo.useMutation<AddUserToProviderMutation, AddUserToProviderMutationVariables>(
    AddUserToProviderDocument,
    baseOptions,
  );
}
export const RemoveUserFromProviderDocument = gql`
  mutation removeUserFromProvider($provider: ID!, $user: ID!) {
    removeUserFromProvider(provider: $provider, user: $user) {
      id
    }
  }
`;
export type RemoveUserFromProviderComponentProps = Omit<
  ApolloComponents.MutationComponentOptions<RemoveUserFromProviderMutation, RemoveUserFromProviderMutationVariables>,
  'mutation'
>;

export const RemoveUserFromProviderComponent = (props: RemoveUserFromProviderComponentProps) => (
  <ApolloComponents.Mutation<RemoveUserFromProviderMutation, RemoveUserFromProviderMutationVariables>
    mutation={RemoveUserFromProviderDocument}
    {...props}
  />
);
export const CreateUserDocument = gql`
  mutation createUser($firstName: String!, $lastName: String!, $email: String!, $admin: Boolean!) {
    createUser(firstName: $firstName, lastName: $lastName, email: $email, admin: $admin) {
      id
    }
  }
`;
/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>,
) {
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
}
export const UpdateUserDocument = gql`
  mutation updateUser($id: ID!, $firstName: String!, $lastName: String!, $email: String!, $admin: Boolean!) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, admin: $admin) {
      id
    }
  }
`;
/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export const CreateRatingDocument = gql`
  mutation createRating(
    $numberOfStars: Int!
    $content: String!
    $userName: String!
    $createdAt: DateTime
    $provider: ID!
  ) {
    adminCreateRating(
      numberOfStars: $numberOfStars
      content: $content
      userName: $userName
      createdAt: $createdAt
      provider: $provider
    ) {
      id
    }
  }
`;
/**
 * __useCreateRatingMutation__
 *
 * To run a mutation, you first call `useCreateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingMutation, { data, loading, error }] = useCreateRatingMutation({
 *   variables: {
 *      numberOfStars: // value for 'numberOfStars'
 *      content: // value for 'content'
 *      userName: // value for 'userName'
 *      createdAt: // value for 'createdAt'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCreateRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRatingMutation, CreateRatingMutationVariables>,
) {
  return Apollo.useMutation<CreateRatingMutation, CreateRatingMutationVariables>(CreateRatingDocument, baseOptions);
}
export const UpdateRatingDocument = gql`
  mutation updateRating(
    $id: ID!
    $numberOfStars: Int!
    $content: String!
    $userName: String
    $createdAt: DateTime
    $providerFeedback: String
  ) {
    updateRating(
      id: $id
      numberOfStars: $numberOfStars
      content: $content
      userName: $userName
      createdAt: $createdAt
      providerFeedback: $providerFeedback
    ) {
      id
    }
  }
`;
/**
 * __useUpdateRatingMutation__
 *
 * To run a mutation, you first call `useUpdateRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRatingMutation, { data, loading, error }] = useUpdateRatingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      numberOfStars: // value for 'numberOfStars'
 *      content: // value for 'content'
 *      userName: // value for 'userName'
 *      createdAt: // value for 'createdAt'
 *      providerFeedback: // value for 'providerFeedback'
 *   },
 * });
 */
export function useUpdateRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRatingMutation, UpdateRatingMutationVariables>,
) {
  return Apollo.useMutation<UpdateRatingMutation, UpdateRatingMutationVariables>(UpdateRatingDocument, baseOptions);
}
export const PhotoDocument = gql`
  query photo($id: ID!) {
    photo(id: $id) {
      id
      name
      link
      createdAt
      showInInspiration
      showInGallery
      priorityGallery
      priorityInspiration
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      provider {
        id
        name
        slug
      }
    }
  }
`;
/**
 * __usePhotoQuery__
 *
 * To run a query within a React component, call `usePhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePhotoQuery(baseOptions?: Apollo.QueryHookOptions<PhotoQuery, PhotoQueryVariables>) {
  return Apollo.useQuery<PhotoQuery, PhotoQueryVariables>(PhotoDocument, baseOptions);
}
export const InspirationPhotosDocument = gql`
  query inspirationPhotos {
    photos(onlyInspiration: true) {
      id
      name
      link
      showInInspiration
      categories {
        id
        name
      }
      provider {
        id
        name
      }
    }
  }
`;
/**
 * __useInspirationPhotosQuery__
 *
 * To run a query within a React component, call `useInspirationPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspirationPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspirationPhotosQuery({
 *   variables: {
 *   },
 * });
 */
export function useInspirationPhotosQuery(
  baseOptions?: Apollo.QueryHookOptions<InspirationPhotosQuery, InspirationPhotosQueryVariables>,
) {
  return Apollo.useQuery<InspirationPhotosQuery, InspirationPhotosQueryVariables>(
    InspirationPhotosDocument,
    baseOptions,
  );
}
export const ProviderDocument = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      oldId
      cashback
      name
      slug
      registrationNumber
      taxRegistrationNumber
      description
      contactEmail
      socialTwitter
      socialFacebook
      socialPinterest
      socialInstagram
      socialYoutube
      membershipFrom
      membershipTo
      priority
      published
      image
      imageCropPosition
      userLikesCount
      videos {
        id
        link
        type
        priority
      }
      stores {
        id
        name
        address
        priority
        city {
          id
          name
        }
      }
      salesman {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
      category {
        id
        name
      }
      tags {
        id
        name
      }
      products {
        id
        name
        price
        priority
        photo {
          id
          name
          link
        }
      }
      services {
        id
        name
        priceFrom
        priceTo
        priority
        description
      }
      users {
        id
        firstName
        lastName
        email
      }
      photos {
        id
        name
        link
        showInInspiration
        showInGallery
        priorityInspiration
        priorityGallery
        categories {
          id
          name
        }
        tags {
          id
          name
        }
      }
      ratings {
        id
        numberOfStars
        content
        providerFeedback
        feedbackAt
        userName
        user {
          id
          firstName
          lastName
        }
      }
      plannerUsers {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useProviderQuery__
 *
 * To run a query within a React component, call `useProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderQuery(baseOptions?: Apollo.QueryHookOptions<ProviderQuery, ProviderQueryVariables>) {
  return Apollo.useQuery<ProviderQuery, ProviderQueryVariables>(ProviderDocument, baseOptions);
}
export const ProvidersDocument = gql`
  query providers {
    providers(includeDrafts: true) {
      id
      name
      image
      published
      users {
        email
      }
    }
  }
`;
/**
 * __useProvidersQuery__
 *
 * To run a query within a React component, call `useProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProvidersQuery(baseOptions?: Apollo.QueryHookOptions<ProvidersQuery, ProvidersQueryVariables>) {
  return Apollo.useQuery<ProvidersQuery, ProvidersQueryVariables>(ProvidersDocument, baseOptions);
}
export const ProviderAnalyticsDocument = gql`
  query providerAnalytics($providerSlug: String!) {
    providerAnalytics(providerSlug: $providerSlug) {
      clicked_gps
      dial_email
      go_to_about
      go_to_contacts
      go_to_facebook
      go_to_gallery
      go_to_instagram
      go_to_products
      go_to_ratings
      go_to_services
      go_to_twitter
      go_to_videos
      go_to_web
      go_to_youtube
      hide_message_form
      play_video
      send_email
      show_email
      show_less_product_info
      show_message_form
      show_message_form_sidebar
      show_more_contacts
      show_more_product_info
      show_phone
      show_photo
      show_web
      visit
    }
  }
`;
export function useProviderAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderAnalyticsQuery, ProviderAnalyticsQueryVariables>,
) {
  return Apollo.useLazyQuery<ProviderAnalyticsQuery, ProviderAnalyticsQueryVariables>(
    ProviderAnalyticsDocument,
    baseOptions,
  );
}
export const CategoriesDocument = gql`
  query categories {
    categories {
      id
      name
      filters {
        id
        name
        tags {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
  return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
}
export const CategoriesPriceDocument = gql`
  query categoriesPrice {
    categories {
      id
      name
      slug
      priceStart
      priceStandard
      priceExtra
      pricePremium
    }
  }
`;
/**
 * __useCategoriesPriceQuery__
 *
 * To run a query within a React component, call `useCategoriesPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesPriceQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesPriceQuery(
  baseOptions?: Apollo.QueryHookOptions<CategoriesPriceQuery, CategoriesPriceQueryVariables>,
) {
  return Apollo.useQuery<CategoriesPriceQuery, CategoriesPriceQueryVariables>(CategoriesPriceDocument, baseOptions);
}
export const FiltersDocument = gql`
  query filters {
    filters {
      id
      name
      activeForProvider
      activeForInspiration
      categories {
        id
      }
      tags {
        id
        name
        slug
      }
    }
  }
`;
/**
 * __useFiltersQuery__
 *
 * To run a query within a React component, call `useFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFiltersQuery(baseOptions?: Apollo.QueryHookOptions<FiltersQuery, FiltersQueryVariables>) {
  return Apollo.useQuery<FiltersQuery, FiltersQueryVariables>(FiltersDocument, baseOptions);
}
export const PostsDocument = gql`
  query posts {
    posts(includeDrafts: true) {
      id
      name
      image
      slug
      publishedAt
    }
  }
`;
/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
  return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
}
export const PostDocument = gql`
  query post($id: ID!) {
    post(id: $id) {
      id
      name
      slug
      image
      imageAlt
      publishedAt
      perex
      content
      category {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostQuery(baseOptions?: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
  return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
}
export const UsersDocument = gql`
  query users($adminOnly: Boolean) {
    users(adminOnly: $adminOnly) {
      id
      firstName
      lastName
      email
      admin
      providers {
        id
        name
      }
    }
  }
`;
/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      adminOnly: // value for 'adminOnly'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
}
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      admin
    }
  }
`;
/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
}
export const ProductDocument = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      name
      priority
      price
      description
      link
      photo {
        id
      }
    }
  }
`;
/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions?: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, baseOptions);
}
export const ServiceDocument = gql`
  query service($id: ID!) {
    service(id: $id) {
      id
      name
      priceFrom
      priceTo
      priority
      description
    }
  }
`;
/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions?: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
  return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, baseOptions);
}
export const VideoDocument = gql`
  query video($id: ID!) {
    video(id: $id) {
      id
      link
      type
      priority
    }
  }
`;
/**
 * __useVideoQuery__
 *
 * To run a query within a React component, call `useVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoQuery(baseOptions?: Apollo.QueryHookOptions<VideoQuery, VideoQueryVariables>) {
  return Apollo.useQuery<VideoQuery, VideoQueryVariables>(VideoDocument, baseOptions);
}
export const StoreDocument = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      name
      address
      lat
      lng
      phone
      web
      email
      openingHours
      priority
      zipCode
      city {
        id
      }
      provider {
        id
      }
    }
  }
`;
/**
 * __useStoreQuery__
 *
 * To run a query within a React component, call `useStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreQuery(baseOptions?: Apollo.QueryHookOptions<StoreQuery, StoreQueryVariables>) {
  return Apollo.useQuery<StoreQuery, StoreQueryVariables>(StoreDocument, baseOptions);
}
export const RatingDocument = gql`
  query rating($id: ID!) {
    rating(id: $id) {
      id
      numberOfStars
      userName
      content
      providerFeedback
      feedbackAt
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
/**
 * __useRatingQuery__
 *
 * To run a query within a React component, call `useRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRatingQuery(baseOptions?: Apollo.QueryHookOptions<RatingQuery, RatingQueryVariables>) {
  return Apollo.useQuery<RatingQuery, RatingQueryVariables>(RatingDocument, baseOptions);
}
export const CitiesDocument = gql`
  query cities {
    cities {
      id
      name
    }
  }
`;
/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
  return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, baseOptions);
}
export const RegionsDocument = gql`
  query regions {
    regions {
      id
      name
      shortName
      priceMultiplier
    }
  }
`;
/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
  return Apollo.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, baseOptions);
}
export const PriceItemsDocument = gql`
  query priceItems {
    priceItems {
      id
      name
      price
      priority
    }
  }
`;
/**
 * __usePriceItemsQuery__
 *
 * To run a query within a React component, call `usePriceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePriceItemsQuery(baseOptions?: Apollo.QueryHookOptions<PriceItemsQuery, PriceItemsQueryVariables>) {
  return Apollo.useQuery<PriceItemsQuery, PriceItemsQueryVariables>(PriceItemsDocument, baseOptions);
}
