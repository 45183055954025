import React from 'react';
import cookie from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';

const SetUserTokenPage = () => {
  const navigate = useNavigate();
  const { token } = useParams<'token'>();
  cookie.set('token', token ?? '');
  navigate('/');
  return <Page></Page>;
};

export default SetUserTokenPage;
