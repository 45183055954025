import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import Page from '../components/Page';
import { useCategoriesPriceQuery, useRegionsQuery, usePriceItemsQuery } from '../graphql/types';
import styled from 'styled-components';
import Flex from '../components/Flex';

const PricingsPage = () => {
  const { loading, data, error } = useCategoriesPriceQuery();

  const regions = useRegionsQuery();

  const priceItems = usePriceItemsQuery();

  if (
    loading ||
    error ||
    !data ||
    !data.categories ||
    regions.loading ||
    regions.error ||
    !regions.data ||
    !regions.data.regions ||
    !priceItems.data ||
    !priceItems.data.priceItems
  ) {
    return <Page title="Ceníky" isLoading={loading} isError={!!error} />;
  }

  return (
    <Page title="Ceníky">
      <SubHeading>Kategorie</SubHeading>
      <DynamicTable
        // @ts-ignore
        head={{
          cells: [
            { key: 'name', content: 'Název' },
            { key: 'start', content: 'Start' },
            { key: 'standard', content: 'Standard' },
            { key: 'extra', content: 'Extra' },
            { key: 'premium', content: 'Premium' },
          ],
        }}
        rows={
          data.categories &&
          data.categories.map((category) => ({
            cells: [
              { key: 'name', content: category.name },
              { key: 'start', content: category.priceStart },
              { key: 'standard', content: category.priceStandard },
              { key: 'extra', content: category.priceExtra },
              { key: 'premium', content: category.pricePremium },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={undefined}
        isLoading={loading}
        emptyView={<div>Žádné kategorie.</div>}
      />
      <SubHeading>Kraje</SubHeading>
      <DynamicTable
        head={{
          cells: [
            { key: 'name', content: 'Název' },
            { key: 'koef', content: 'Koeficient' },
          ],
        }}
        rows={
          regions.data.regions &&
          regions.data.regions.map((region) => ({
            cells: [
              { key: 'name', content: region.name },
              { key: 'koef', content: region.priceMultiplier },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={undefined}
        isLoading={loading}
        emptyView={<div>Žádné kraje.</div>}
      />

      <Flex>
        <SubHeading>Položky</SubHeading>
      </Flex>
      <DynamicTable
        head={{
          cells: [
            { key: 'name', content: 'Název' },
            { key: 'price', content: 'Cena' },
          ],
        }}
        rows={
          priceItems.data.priceItems &&
          priceItems.data.priceItems.map((item) => ({
            cells: [
              { key: 'name', content: item.name },
              { key: 'price', content: item.price },
            ],
          }))
        }
        defaultPage={1}
        loadingSpinnerSize="large"
        rowsPerPage={undefined}
        isLoading={loading}
        emptyView={<div>Žádné položky.</div>}
      />
    </Page>
  );
};

const SubHeading = styled.h2`
  margin-bottom: 20px;
`;

export default PricingsPage;
