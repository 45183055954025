import cookies from 'js-cookie';
import React from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import { ButtonGroup } from '@atlaskit/button';
import Icon from './Icon';

const MenuFlexDividier = () => <div style={{ height: 10, flex: '1 1 auto' }} />;

const MenuItem = (props: { onClick: () => void; icon: string; text: string; active?: boolean }) => (
  <button
    onClick={(e) => {
      e.preventDefault();
      props.onClick();
    }}
    style={{
      color: props.active ? '#ffffff' : '#ffffff',
      border: 'none',
      background: props.active ? 'rgba(0,0,0, 0.2)' : 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
      padding: '18px 20px',
      margin: 0,
      cursor: 'pointer',
    }}
  >
    {props.icon && <Icon name={props.icon} color="#ffffff" style={{ marginRight: 10 }} />}

    {props.text}
  </button>
);

const Menu = () => {
  let navigate = useNavigate();

  const signOut = () => {
    cookies.remove('token');
    navigate('/login');
  };

  const match = (path: string) => {
    return (
      matchPath(
        {
          path,
          caseSensitive: false,
          end: true,
        },
        path,
      ) !== null
    );
  };

  return (
    <div
      style={{
        backgroundColor: '#f7bfb5',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          listStyle: 'none',
          margin: '0',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MenuItem
          text={`Dodavatelé`}
          icon="company"
          onClick={() => navigate('/providers')}
          active={match('/providers')}
        />

        <MenuItem
          text={`Příspěvky`}
          icon="stack-of-photos"
          onClick={() => navigate('/posts')}
          active={match('/posts')}
        />

        <MenuItem text={`Uživatelé`} icon="user" onClick={() => navigate('/users')} active={match('/users')} />

        <MenuItem text={`Buildy`} icon="bitbucket" onClick={() => navigate('/builds')} active={match('/builds')} />

        <MenuItem text={`Ceník`} icon="price-tag-usd" onClick={() => navigate('/pricing')} active={match('/pricing')} />

        <MenuItem
          text={`Inspirace`}
          icon="stack-of-photos"
          onClick={() => navigate('/photos')}
          active={match('/photos')}
        />

        <MenuFlexDividier />
        <MenuFlexDividier />
        <ButtonGroup>
          <MenuItem text="Odhlásit se" icon="export" onClick={() => signOut()} />
        </ButtonGroup>
      </div>
    </div>
  );
};

export default Menu;
