import 'rc-switch/assets/index.css';
import { Form, Formik } from 'formik';
import getValue from 'get-value';
import _ from 'lodash';
import Switch from 'rc-switch';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@atlaskit/button';
import { Checkbox } from '@atlaskit/checkbox';
import SectionMessage from '@atlaskit/section-message';
import Select from '@atlaskit/select';
import Card from '../components/Card';
import FileField from '../components/FileField';
import Flex from '../components/Flex';
import Label from '../components/Label';
import Page from '../components/Page';
import TextField from '../components/TextField';
import { PHOTO_QUERY, PROVIDER_QUERY } from '../graphql/graphqlQueries';
import {
  useCategoriesQuery,
  useCreatePhotoMutation,
  useFiltersQuery,
  usePhotoQuery,
  useUpdatePhotoMutation,
} from '../graphql/types';

const CreateAndEditPhotoSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Zadejte minimálně 2 znaky')
    .max(200, 'Toto pole je dlouhé. Zadejte maximálně 200 znaků.')
    .required('Toto pole je povinné.'),
  link: Yup.string().url().required('Toto pole je povinné.'),
  showInInspiration: Yup.boolean().required(),
  showInGallery: Yup.boolean().required(),
  categories: Yup.array().min(1).required(),
  priorityGallery: Yup.number().integer('Pouze celé číslo.').positive('Pouze kladné celé číslo.').nullable(),
  priorityInspiration: Yup.number().integer('Pouze celé číslo.').positive('Pouze kladné celé číslo.').nullable(),
  tags: Yup.array().min(1).required(),
});

const CreatePhotoPage = () => {
  const navigate = useNavigate();
  const { photoId } = useParams<'photoId'>();
  const { providerId } = useParams<'providerId'>();
  const photoIdParam = photoId !== 'add' ? photoId : undefined;
  const categoriesFetcher = useCategoriesQuery();
  const filtersFetcher = useFiltersQuery();
  const photoQuery = usePhotoQuery({ variables: { id: photoIdParam + '' }, skip: !photoIdParam });
  const [executeCreatePhotoMutation, createPhotoMutation] = useCreatePhotoMutation();
  const [executeUpdatePhotoMutation, updatePhotoMutation] = useUpdatePhotoMutation();

  if (photoIdParam && photoQuery && photoQuery.called && !photoQuery.data) {
    return <Page title="Upravit fotografii" isLoading={photoQuery.loading} isError={!!photoQuery.error} />;
  }

  if (filtersFetcher.loading || categoriesFetcher.loading || categoriesFetcher.error || filtersFetcher.error) {
    return (
      <Page
        title={!photoIdParam ? 'Přidat fotografii' : 'Upravit fotografii'}
        isLoading={categoriesFetcher.loading || filtersFetcher.loading}
        isError={!!categoriesFetcher.error || !!filtersFetcher.error}
      />
    );
  }

  if (
    (createPhotoMutation && createPhotoMutation.data && getValue(createPhotoMutation.data, 'createPhoto.id')) ||
    (updatePhotoMutation && updatePhotoMutation.data && getValue(updatePhotoMutation.data, 'updatePhoto.id'))
  ) {
    navigate(`/providers/${providerId}`);
  }

  return (
    <Page title={!photoIdParam ? 'Přidat fotografii' : 'Upravit fotografii'}>
      <Formik
        initialValues={{
          name: photoIdParam && photoQuery.data ? photoQuery.data.photo.name : '',
          link: photoIdParam && photoQuery.data ? photoQuery.data.photo.link : '',
          priorityGallery: photoIdParam && photoQuery.data ? photoQuery.data.photo.priorityGallery || '' : '',
          priorityInspiration: photoIdParam && photoQuery.data ? photoQuery.data.photo.priorityInspiration || '' : '',
          showInInspiration: photoIdParam && photoQuery.data ? photoQuery.data.photo.showInInspiration : true,
          showInGallery: photoIdParam && photoQuery.data ? photoQuery.data.photo.showInGallery : true,
          categories:
            photoIdParam && photoQuery.data ? photoQuery.data.photo.categories.map((category) => category.id) : [],
          tags: photoIdParam && photoQuery.data ? photoQuery.data.photo.tags.map((tag) => tag.id) : [],
        }}
        isInitialValid={!!photoIdParam}
        validationSchema={CreateAndEditPhotoSchema}
        onSubmit={async (values) => {
          if (photoIdParam) {
            await executeUpdatePhotoMutation({
              variables: {
                ...values,
                priorityGallery: !values.showInGallery
                  ? null
                  : values.priorityGallery === ''
                  ? null
                  : Number.parseInt(values.priorityGallery + ''),
                priorityInspiration: !values.showInInspiration
                  ? null
                  : values.priorityInspiration === ''
                  ? null
                  : Number.parseInt(values.priorityInspiration + ''),
                id: photoIdParam,
              },
              refetchQueries: [
                { query: PROVIDER_QUERY, variables: { id: providerId } },
                { query: PHOTO_QUERY, variables: { id: photoIdParam } },
              ],
            });
          } else {
            await executeCreatePhotoMutation({
              variables: {
                ...values,
                priorityGallery: !values.showInGallery
                  ? null
                  : values.priorityGallery === ''
                  ? null
                  : Number.parseInt(values.priorityGallery + ''),
                priorityInspiration: !values.showInInspiration
                  ? null
                  : values.priorityInspiration === ''
                  ? null
                  : Number.parseInt(values.priorityInspiration + ''),
                providerId: providerId ?? '',
              },
              refetchQueries: [{ query: PROVIDER_QUERY, variables: { id: providerId } }],
            });
          }
        }}
      >
        {(props) => (
          <Form>
            {createPhotoMutation.error ||
              (updatePhotoMutation.error && (
                <SectionMessage appearance="error">Nepovedlo se fotografii uložit.</SectionMessage>
              ))}
            <Flex>
              <TextField formikProps={props} label="Název" name="name" autoFocus={true} isRequired />

              <Flex>
                <Card title="Zobrazit v galerii" isRequired>
                  <Switch
                    checked={props.values.showInGallery}
                    onClick={(state) => {
                      props.setFieldValue('showInGallery', state);
                    }}
                    checkedChildren={null}
                    unCheckedChildren={null}
                  />
                </Card>
                <Card title="Zobrazit v inspiraci" isRequired>
                  <Switch
                    checked={props.values.showInInspiration}
                    onClick={(state) => {
                      props.setFieldValue('showInInspiration', state);
                    }}
                    checkedChildren={null}
                    unCheckedChildren={null}
                  />
                </Card>
              </Flex>

              {props.values.showInGallery && (
                <TextField formikProps={props} label="Priorita v galerii" name="priorityGallery" type="number" />
              )}

              {props.values.showInInspiration && (
                <TextField formikProps={props} label="Priorita v inspiraci" name="priorityInspiration" type="number" />
              )}

              <Card title="Kategorie" isRequired>
                <Select
                  options={
                    categoriesFetcher && categoriesFetcher.data && categoriesFetcher.data.categories
                      ? [
                          ...categoriesFetcher.data.categories.map((category) => ({
                            label: category.name,
                            value: category.id,
                          })),
                        ]
                      : {}
                  }
                  name="categories"
                  onChange={(array: { value: string; label: string }[]) => {
                    props.setFieldValue('categories', array ? array.map((item) => item.value) : []);
                    props.setFieldValue('tags', []);
                  }}
                  value={(function () {
                    if (props.values.categories.length > 0) {
                      if (
                        categoriesFetcher.data &&
                        categoriesFetcher.data.categories &&
                        categoriesFetcher.data.categories.length
                      ) {
                        return categoriesFetcher.data.categories
                          .filter((category) => props.values.categories.includes(category.id))
                          .map((category) => ({ label: category.name, value: category.id }));
                      }
                    } else {
                      return [];
                    }
                  })()}
                  isMulti
                  isSearchable={true}
                  placeholder="Vyberte alespoň jednu kategorii"
                />
              </Card>
            </Flex>

            <Card title="Obrázek" isRequired>
              <FileField
                updateParentState={(url) => {
                  props.setFieldValue('link', url);
                }}
                defaultState={props.values.link}
                photoName={props.values.name}
              />
            </Card>

            <h2>Štítky</h2>
            {props.values.categories.length < 1 && (
              <div style={{ marginTop: 15 }}>
                <SectionMessage>Vyberte nejprve alespoň jednu kategorii.</SectionMessage>
              </div>
            )}
            <Flex columns={5}>
              {filtersFetcher &&
                filtersFetcher.data &&
                filtersFetcher.data.filters
                  .filter((filter) => filter.activeForInspiration)
                  .filter((filter) => {
                    const categories = filter.categories.map((category) => category.id);
                    const intersection = _.intersection(categories, props.values.categories);

                    if (intersection.length > 0) {
                      return true;
                    }

                    return false;
                  })
                  .filter((filter) => filter.tags.length > 0)
                  .map((filter) => (
                    <div key={filter.id}>
                      <Label label={filter.name} />
                      {filter.tags.map((tag) => (
                        <Checkbox
                          isChecked={props.values.tags.includes(tag.id)}
                          label={`${tag.name} (${tag.slug})`}
                          onChange={() => {
                            var index = props.values.tags.indexOf(tag.id);
                            if (index > -1) {
                              let tags = props.values.tags;
                              tags.splice(index, 1);
                              props.setFieldValue('tags', tags);
                            } else {
                              let tags = props.values.tags;
                              tags.push(tag.id);
                              props.setFieldValue('tags', tags);
                            }
                          }}
                        />
                      ))}
                    </div>
                  ))}
            </Flex>
            <div style={{ marginTop: 20 }}>
              <LoadingButton
                type="submit"
                isLoading={createPhotoMutation.loading || updatePhotoMutation.loading}
                isDisabled={!props.isValid || createPhotoMutation.loading || updatePhotoMutation.loading}
                appearance="primary"
              >
                Uložit
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreatePhotoPage;
