import React from 'react';
import styled from 'styled-components';
import Label from './Label';

const Card = (props: { title: string; isRequired?: boolean; children?: any }) => (
  <Wrapper>
    <Label label={props.title} isRequired={props.isRequired} />
    {props.children}
  </Wrapper>
);

const Wrapper = styled.div`
  font-size: 15px;
`;

export default Card;
